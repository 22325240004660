module.exports = [
    {
        href: '/portfolio/anand-sweets',
        onMouseOverColor: { bg: '#1B1830', text: '#9C8EFF' },
        imgSrc: '/assets/image/anand_sweets.webp',
        imgAlt: 'anand sweets',
        projectName: 'Anand Sweets',
        projectInfoOne: 'Traditional Indian Sweets:',
        projectInfoTwo: 'A Taste of Royal India',
        descriptionOne: 'Shopify, UI/UX, and SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/dr-vaidya',
        onMouseOverColor: { bg: '#083544', text: '#BDF0FF' },
        imgSrc: '/assets/image/dr_vaidiya.webp',
        imgAlt: 'dr vaidyas',
        projectName: 'Dr. Vaidyas',
        projectInfoOne: 'Ayurvedic Health and Stamina Supplements',
        descriptionOne: 'Shopify, UI/UX, Graphic Designing',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/yogabar',
        onMouseOverColor: { bg: '#0C052B', text: '#AF5FFF' },
        imgSrc: '/assets/image/yogabar.webp',
        imgAlt: 'yogabar',
        projectName: 'YogaBar',
        projectInfoOne: 'Healthy protein-based foods',
        descriptionOne: 'Shopify, UI/UX, Graphic Designing,',
        descriptionTwo: 'Site Maintenance',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/chhappanbhog',
        onMouseOverColor: { bg: '#171C26', text: '#8DC0FF' },
        imgSrc: '/assets/image/chappanbhog.webp',
        imgAlt: 'chappanbhog',
        projectName: 'Patel’s Chhappan Bhog',
        projectInfoOne: 'Traditional Indian',
        projectInfoTwo: 'Ready-to-eat cuisine',
        descriptionOne: 'Shopify, UI/UX, Graphic Designing',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/giver-nutritions',
        onMouseOverColor: { bg: '#2E170A', text: '#FFD4AC' },
        imgSrc: '/assets/image/giver.webp',
        imgAlt: 'giver',
        projectName: 'Giver Nutritions',
        projectInfoOne: 'A health supplement company focused on Mental Health',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/hyfun',
        onMouseOverColor: { bg: '#102602', text: '#DEFBCC' },
        imgSrc: '/assets/image/hyfun.webp',
        imgAlt: 'hyfun',
        projectName: 'HyFun Foods',
        projectInfoOne: '100% veg. frozen snacks. Made with finest vegetables.',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/thenga',
        onMouseOverColor: { bg: '#231302', text: '#EDEAE0' },
        imgSrc: '/assets/image/thenga.webp',
        imgAlt: 'thenga',
        projectName: 'Thenga Coco',
        projectInfoOne: 'Transforming coconut wastes into eco-friendly treasures.',
        descriptionOne: 'Shopify, UI/UX, Onsite SEO, StartUp',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/sportsaber',
        onMouseOverColor: { bg: '#260C04', text: '#EF6940' },
        imgSrc: '/assets/image/sport_saber.webp',
        imgAlt: 'sport saber',
        projectName: 'Sport Saber',
        projectInfoOne: 'Elite gear, vibrant community, and charitable support.',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/ubar',
        onMouseOverColor: { bg: '#401603', text: '#F5D8CB' },
        imgSrc: '/assets/image/ubar.webp',
        imgAlt: 'ubar',
        projectName: 'Ubar',
        projectInfoOne: 'Elevate Wellness with Premium Health Supplements.',
        descriptionOne: 'Shopify, UI/UX, Packaging Design, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/radhey-sweets',
        onMouseOverColor: { bg: '#04272E', text: '#FFFBD2' },
        imgSrc: '/assets/image/radhey_sweets.webp',
        imgAlt: 'radhey sweets',
        projectName: 'Radhey Sweets',
        projectInfoOne: 'Authentic Flavours, Irresistible Indian Sweets and Namkeen.',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/maa-and-baby',
        onMouseOverColor: { bg: '#014263', text: '#E8FAFF' },
        imgSrc: '/assets/image/maababy.webp',
        imgAlt: 'maa & baby',
        projectName: 'Maa & Baby',
        projectInfoOne: 'Baby Products for parent\'s Precious Moments',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/ekki-bekki',
        onMouseOverColor: { bg: '#07313C', text: '#E8FAFF' },
        imgSrc: '/assets/image/ekkibekki.webp',
        imgAlt: 'Ekkibekki',
        projectName: 'Ekkibekki',
        projectInfoOne: 'Wallpaper designs for artful interiors',
        descriptionOne: 'Shopify, UI/UX, Branding, Integrations',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/teatox-life',
        onMouseOverColor: { bg: '#0D2318', text: '#E8FAFF' },
        imgSrc: '/assets/image/teatox.webp',
        imgAlt: 'TeaTox Life',
        projectName: 'TeaTox Life',
        projectInfoOne: 'Natural Herbal Wellness for Healthy Living',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/naturare',
        onMouseOverColor: { bg: '#011A0D', text: '#E8FAFF' },
        imgSrc: '/assets/image/thumbnailimage/naturare.webp',
        imgAlt: 'naturare',
        projectName: 'Naturare',
        projectInfoOne: 'Transforming wellness and empowering lives naturally.',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/neo-roots',
        onMouseOverColor: { bg: '#14332C', text: '#E8FAFF' },
        imgSrc: '/assets/image/thumbnailimage/neoroots.webp',
        imgAlt: 'neoroots',
        projectName: 'Neo Roots',
        projectInfoOne: 'Custom greenery for indoor and outdoor spaces.',
        descriptionOne: 'Shopify, UI/UX, Branding, Integrations',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/uppercase',
        onMouseOverColor: { bg: '#063541', text: '#D1F2F8' },
        imgSrc: '/assets/image/uppercase.webp',
        imgAlt: 'Uppercase',
        projectName: 'Uppercase',
        projectInfoOne: 'Eco-Friendly Travel Gear with Stylish Design.',
        descriptionOne: 'Shopify, UI/UX, Branding, Integrations',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/healing-hands',
        onMouseOverColor: { bg: '#042C2F', text: '#AAF9FF' },
        imgSrc: '/assets/image/healing.webp',
        imgAlt: 'Healing Hands',
        projectName: 'Healing Hands',
        projectInfoOne: 'Plant-based meds for Piles and Constipation Relief',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/kutum-ayurveda',
        onMouseOverColor: { bg: '#975C24', text: '#DCB793' },
        imgSrc: '/assets/image/kutum.webp',
        imgAlt: 'Kutum Ayurveda',
        projectName: 'Kutum Ayurveda',
        projectInfoOne: 'Pure Ayurvedic Care for Mind, Body & Spirit.',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/nurdle',
        onMouseOverColor: { bg: '#1E2B3C', text: '#959EAB' },
        imgSrc: '/assets/image/nurdle.webp',
        imgAlt: 'Nurdle',
        projectName: 'Nurdle',
        projectInfoOne: `Colorful, Comfortable Kids' Underwear with Surprise Gifts.`,
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/technosport',
        onMouseOverColor: { bg: '#1B1B1B', text: '#888888' },
        imgSrc: '/assets/image/technosport.webp',
        imgAlt: 'Technosport',
        projectName: 'Techno Sport',
        projectInfoOne: 'Athletic and stylish athleisure wear brand.',
        descriptionOne: 'Shopify, Development, Integrations, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/myoa-cookies',
        onMouseOverColor: { bg: '#29120F', text: '#F5DFBB' },
        imgSrc: '/assets/image/myoa_cookies.webp',
        imgAlt: 'Myoa Cookies',
        projectName: 'Myoa Cookies',
        projectInfoOne: 'Luxury cookies with premium ingredients - baked fresh.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/bontrue-furniture',
        onMouseOverColor: { bg: '#280E0A', text: '#AF827B' },
        imgSrc: '/assets/image/bontrue_furniture.webp',
        imgAlt: 'Bontrue Furniture',
        projectName: 'Bontrue Furniture',
        projectInfoOne: 'Stylish and functional furniture for modern living spaces.',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/nourysh',
        onMouseOverColor: { bg: '#01191F', text: '#E1E4FF' },
        imgSrc: '/assets/image/nourysh.webp',
        imgAlt: 'nourysh',
        projectName: 'Nourysh',
        projectInfoOne: 'Nutritional Formulations',
        projectInfoTwo: 'for Health',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/bayla',
        onMouseOverColor: { bg: '#2C3001', text: '#EFFFBE' },
        imgSrc: '/assets/image/bayla.webp',
        imgAlt: 'bayla',
        projectName: 'Bayla Skins',
        projectInfoOne: 'Natural and Science-Backed Skincare',
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/prakruti',
        onMouseOverColor: { bg: '#022816', text: '#90E3BD' },
        imgSrc: '/assets/image/prakruti.webp',
        imgAlt: 'prakruti',
        projectName: 'Prakruti Pure Herbs',
        projectInfoOne: 'Best Ayurvedic Medicine, Health supplements,',
        projectInfoTwo: 'Skin & Hair Care',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/hamdard',
        onMouseOverColor: { bg: '#2E0304', text: '#FBE5E6' },
        imgSrc: '/assets/image/hamdard.webp',
        imgAlt: 'hamdard',
        projectName: 'Hamdard',
        projectInfoOne: "India's leading unani medicine brand.",
        descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/shistaka',
        onMouseOverColor: { bg: '#241B03', text: '#F2E2BA' },
        imgSrc: '/assets/image/shistaka.webp',
        imgAlt: 'shistaka',
        projectName: 'Shistaka',
        projectInfoOne: 'Blending Ayurvedic wisdom with modern wellness philosophy.',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/freshleaf',
        onMouseOverColor: { bg: '#212721', text: '#F5FAE2' },
        imgSrc: '/assets/image/freshleaf.webp',
        imgAlt: 'freshleaf',
        projectName: 'FreshLeaf',
        projectInfoOne: 'Pure, handpicked teas for ultimate freshness.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/healthoxide',
        onMouseOverColor: { bg: '#052F46', text: '#E8FAFF' },
        imgSrc: '/assets/image/healthoxide.webp',
        imgAlt: 'healthoxide',
        projectName: 'Healthoxide',
        projectInfoOne: 'Elevate Your Health and Fitness Journey',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/gogrub',
        onMouseOverColor: { bg: '#031929', text: '#E8FAFF' },
        imgSrc: '/assets/image/gogrub.webp',
        imgAlt: 'go grub',
        projectName: 'Go Grub',
        projectInfoOne: 'Discover Delicious Wholesome Pantry Picks',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/mypro',
        onMouseOverColor: { bg: '#161616', text: '#E8FAFF' },
        imgSrc: '/assets/image/mypro.webp',
        imgAlt: 'My Pro',
        projectName: 'My Pro Sport Nutritions',
        projectInfoOne: 'Fueling fitness, wellness, and peak performance',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/aumex',
        onMouseOverColor: { bg: '#050121', text: '#E8FAFF' },
        imgSrc: '/assets/image/aumex.webp',
        imgAlt: 'Aumex',
        projectName: 'Aumex',
        projectInfoOne: 'Revitalize Intimate Wellness with Care',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/within-beauty',
        onMouseOverColor: { bg: '#412382', text: '#E3DCFF' },
        imgSrc: '/assets/image/within.webp',
        imgAlt: 'Within Beauty',
        projectName: 'Within Beauty',
        projectInfoOne: 'Skincare products with natural, cruelty-free ingredients.',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/om-sweets',
        onMouseOverColor: { bg: '#C01F25', text: '#FBF4EC' },
        imgSrc: '/assets/image/omsweets.webp',
        imgAlt: 'om sweets',
        projectName: 'Om Sweets & Snacks',
        projectInfoOne: 'Authentic snacks and sweets for every occasion.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/yo-gathiya',
        onMouseOverColor: { bg: '#7C0A21', text: '#FCEED0' },
        imgSrc: '/assets/image/yogathiya.webp',
        imgAlt: 'yo gathiya',
        projectName: 'Yo Gathiya',
        projectInfoOne: 'Savor tradition with premium Indian Namkeen and snacks.',
        descriptionOne: 'Shopify, UI/UX, Branding, Label Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/arospice',
        onMouseOverColor: { bg: '#204F34', text: '#BED0C6' },
        imgSrc: '/assets/image/arospice.webp',
        imgAlt: 'Arospice',
        projectName: 'Arospice',
        projectInfoOne: 'Pure essence of tradition, passion, and magic in every product.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/repeat-gud',
        onMouseOverColor: { bg: '#131313', text: '#FCEED0' },
        imgSrc: '/assets/image/repeat_gud.webp',
        imgAlt: 'Repeat Gud',
        projectName: 'Repeat Gud',
        projectInfoOne: 'Pure, Nutrient-Packed Sauces Delighting Taste Buds and Nourishing.',
        descriptionOne: 'Shopify, UI/UX, Development, Onsite SEO',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/spark-fusion',
        onMouseOverColor: { bg: '#151213', text: '#9E9E9E' },
        imgSrc: '/assets/image/sparkfusion.webp',
        imgAlt: 'sparkfusion',
        projectName: 'Spark Fusion',
        projectInfoOne: 'Premium fitness products for better health and strength.',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/bhagavad-gita',
        onMouseOverColor: { bg: '#451018', text: '#8D5F66' },
        imgSrc: '/assets/image/bhagavad_gita.webp',
        imgAlt: 'Bhagavad Gita',
        projectName: 'Bhagavad Gita',
        projectInfoOne: 'Original Vedic scriptures, books, and spiritual teachings.',
        descriptionOne: 'Shopify, UI/UX, Branding, Product Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/weight-manage',
        onMouseOverColor: { bg: '#283B4B', text: '#FFFFFF' },
        imgSrc: '/assets/image/weightmanage.webp',
        imgAlt: 'Weight Manage',
        projectName: 'Weight manage',
        projectInfoOne: 'Healthy weight manage made effective!',
        descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/agarwalbhavan-sweets',
        onMouseOverColor: { bg: '#171616', text: '#EEEEEE' },
        imgSrc: '/assets/image/agarwal_bhavan.webp',
        imgAlt: 'Agarwal Bhavan Sweets',
        projectName: 'Agarwal Bhavan Sweets',
        projectInfoOne: 'Traditional, authentic, quality Indian sweets and snacks.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/murari-sweets',
        onMouseOverColor: { bg: '#192658', text: '#7283CA' },
        imgSrc: '/assets/image/murari_sweets.webp',
        imgAlt: 'Murari Sweets',
        projectName: 'Murari Sweets',
        projectInfoOne: 'Authentic Indian sweets, diverse selection, divine taste.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/singlas-sweets',
        onMouseOverColor: { bg: '#230E0F', text: '#A57760' },
        imgSrc: '/assets/image/singla_sweet.webp',
        imgAlt: 'Singla Sweets',
        projectName: 'Singla Sweets',
        projectInfoOne: 'Authentic, Handmade, High Quality, Indian Delicacies',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/savani-jewellery',
        onMouseOverColor: { bg: '#E6BB8A', text: '#333333' },
        imgSrc: '/assets/image/savani.webp',
        imgAlt: 'Savani Jewellery',
        projectName: 'Savani Jewellery',
        projectInfoOne: 'Elegant diamond jewelry with sustainable luxury.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/bodyart',
        onMouseOverColor: { bg: '#181A1F', text: '#B59671' },
        imgSrc: '/assets/image/bodyart.webp',
        imgAlt: 'Body Art',
        projectName: 'Body Art',
        projectInfoOne: 'Electrostimulation Fitness & Body Sculpting Solutions.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },
    {
        href: '/portfolio/mia',
        onMouseOverColor: { bg: '#3A1B59', text: '#B79AD3' },
        imgSrc: '/assets/image/mia.webp',
        imgAlt: 'Mia',
        projectName: 'Mia My Ally',
        projectInfoOne: 'Feminine Hygiene Care for Comfort.',
        descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        categories: ['all', 'e-commerce'],
    },



    {
        href: '/portfolio/ui/teatox-life',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelteatox.webp',
        imgAlt: 'TeaTox Life',
        projectName: 'TeaTox Life',
        projectInfoOne: 'Natural Herbal Wellness',
        projectInfoTwo: 'for Healthy Living',
        descriptionOne: 'UI design, UX design, Wireframe',
        categories: ['all', 'ui-design'],
    },
    {
        href: '/portfolio/ui/apple-elevators',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/uiapple.webp',
        imgAlt: 'Apple Elevators',
        projectName: 'Apple Elevators',
        projectInfoOne: 'Elevators mobility with',
        projectInfoTwo: 'innovative solutions.',
        descriptionOne: 'UI design, UX design, Wireframe',
        categories: ['all', 'ui-design'],
    },
    {
        href: '/portfolio/ui/healing-hands',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/uihealing.webp',
        imgAlt: 'Healing Hands',
        projectName: 'Healing Hands',
        projectInfoOne: 'Plant-based Medical',
        projectInfoTwo: 'innovation',
        descriptionOne: 'UI design, UX design, Wireframe',
        categories: ['all', 'ui-design'],
    },
    {
        href: '/portfolio/ui/the-one',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/uitheone.webp',
        imgAlt: 'The One',
        projectName: 'The One',
        projectInfoOne: 'Government blockchain',
        projectInfoTwo: 'innovator, shaping the future.',
        descriptionOne: 'UI design, UX design, Wireframe',
        categories: ['all', 'ui-design'],
    },
    {
        href: '/portfolio/ui/lycanpay',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/uilycanpay.webp',
        imgAlt: 'Lycan Pay',
        projectName: 'Lycan Pay',
        projectInfoOne: 'Revolutionizing payments',
        projectInfoTwo: 'with blockchain technology.',
        descriptionOne: 'UI design, UX design, Wireframe',
        categories: ['all', 'ui-design'],
    },



    {
        href: '/portfolio/label/nourysh',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelnourysh.webp',
        imgAlt: 'Nourysh',
        projectName: 'Nourysh',
        projectInfoOne: 'Fueling fitness, wellness',
        projectInfoTwo: 'and peak performance.',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/ras-beauty',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/ras.webp',
        imgAlt: 'Ras Beauty',
        projectName: 'Ras Beauty',
        projectInfoOne: 'Farm-to-Face Luxury',
        projectInfoTwo: 'Skincare with Ayurvedic Essence',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/moody',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/moody.webp',
        imgAlt: 'Moody',
        projectName: 'Moody',
        projectInfoOne: 'Empowering Women, Radiant Skin, Natural Confidence',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/aumex',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelaumex.webp',
        imgAlt: 'Aumex',
        projectName: 'Aumex',
        projectInfoOne: 'Revitalize Intimate',
        projectInfoTwo: 'Wellness with Care',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/shishtaka',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelshishtaka.webp',
        imgAlt: 'Shishtaka',
        projectName: 'Shishtaka',
        projectInfoOne: 'Blending Ayurvedic wisdom with',
        projectInfoTwo: 'modern wellness philosophy.',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/naturare',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelnaturare.webp',
        imgAlt: 'Naturare',
        projectName: 'Naturare',
        projectInfoOne: 'Transforming wellness and',
        projectInfoTwo: 'empowering lives naturally.',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/teatox-life',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelteatox.webp',
        imgAlt: 'TeaTox Life',
        projectName: 'TeaTox Life',
        projectInfoOne: 'Natural Herbal Wellness',
        projectInfoTwo: 'for Healthy Living.',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/healing-hands',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelhealing.webp',
        imgAlt: 'Healing Hands',
        projectName: 'Healing Hands',
        projectInfoOne: 'Plant-based Medical',
        projectInfoTwo: 'innovation',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/mypro',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/labelmypro.webp',
        imgAlt: 'My Pro Sport Nutritions',
        projectName: 'My Pro Sport Nutritions',
        projectInfoOne: 'Fueling fitness, wellness',
        projectInfoTwo: 'and peak performance',
        descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/acnex-topical',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/acnex.webp',
        imgAlt: 'Acne-X',
        projectName: 'Acne-X',
        projectInfoOne: 'Embrace a fresh start,',
        projectInfoTwo: 'leave acne in the past.',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/anthi-naturals',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/anthi.webp',
        imgAlt: 'Anthi',
        projectName: 'Anthi',
        projectInfoOne: "India's First Anti-Thinning Hair Care Brand",
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/terahash-solutions',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/terahash.webp',
        imgAlt: 'Terahash',
        projectName: 'Terahash Solutions',
        projectInfoOne: 'Securing the future of finance one terahash at a time.',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/ras-lipstick',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/lipstick.webp',
        imgAlt: 'Ras Lipstick',
        projectName: 'RAS Lipstick',
        projectInfoOne: 'Smooth, moisturized,',
        projectInfoTwo: 'and vibrant lips all day',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/smart-mop',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/smartmop.webp',
        imgAlt: 'Smart Mop',
        projectName: 'Caspian',
        projectInfoOne: 'Revolutionizing clean',
        projectInfoTwo: 'with every step.',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    // {
    //     href: '/portfolio/label/kayura-effect',
    //     onMouseOverColor: { bg: '#222', text: '#efebe7' },
    //     imgSrc: '/assets/image/thumbnailimage/kayura.webp',
    //     imgAlt: 'Kayura',
    //     projectName: 'Kayura',
    //     projectInfoOne: 'Nourish, protect, and glow—naturally.',
    //     descriptionOne: '3D Render, Modeling, Product Visualization',
    //     categories: ['all', '3d'],
    // },
    {
        href: '/portfolio/label/kutum-ayurveda',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/kutum.webp',
        imgAlt: 'Kutum Ayurveda',
        projectName: 'Kutum Ayurveda',
        projectInfoOne: 'Ancient Wisdom, Modern Wellness.',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/maadico',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/maadico.webp',
        imgAlt: 'Maadico',
        projectName: 'Maadico Wellness',
        projectInfoOne: 'Provided by nature,',
        projectInfoTwo: 'Perfected by science',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/mison-jarin',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/maisonjarin.webp',
        imgAlt: 'Maison Jarin',
        projectName: 'Maison De Jarin',
        projectInfoOne: 'Handcrafted blends for the adventurous soul',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/molecule',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/molecule.webp',
        imgAlt: 'Molecule',
        projectName: 'Molecule 53',
        projectInfoOne: 'Science backed skincare',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    {
        href: '/portfolio/label/pourd-cocktails',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/thumbnailimage/cocktails.webp',
        imgAlt: 'Cocktails',
        projectName: 'Pour’d',
        projectInfoOne: 'Crafted Carefully,',
        projectInfoTwo: 'Enjoyed effortlessly.',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    // {
    //     href: '/portfolio/label/rare-suga',
    //     onMouseOverColor: { bg: '#222', text: '#efebe7' },
    //     imgSrc: '/assets/image/thumbnailimage/raresuga.webp',
    //     imgAlt: 'Rare Suga',
    //     projectName: 'Rare Suga',
    //     projectInfoOne: 'Fueling fitness, wellness',
    //     projectInfoTwo: 'and peak performance',
    //     descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
    //     categories: ['all', '3d'],
    // },
    {
        href: '/portfolio/label/weight-manage',
        onMouseOverColor: { bg: '#222', text: '#efebe7' },
        imgSrc: '/assets/image/weightmanage.webp',
        imgAlt: 'Weight Manage',
        projectName: 'Weight Manage',
        projectInfoOne: 'Your partner for Healthier living',
        descriptionOne: '3D Render, Modeling, Product Visualization',
        categories: ['all', '3d'],
    },
    // {
    //     href: '/portfolio/label/elder',
    //     onMouseOverColor: { bg: '#222', text: '#efebe7' },
    //     imgSrc: '/assets/image/thumbnailimage/elder.webp',
    //     imgAlt: 'Elder',
    //     projectName: 'Elder',
    //     projectInfoOne: 'Fueling fitness, wellness',
    //     projectInfoTwo: 'and peak performance',
    //     descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
    //     categories: ['all', '3d'],
    // },


    {
        href: '/portfolio/branding/abox',
        onMouseOverColor: { bg: '#333', text: '#fff' },
        imgSrc: '/assets/image/abox.webp',
        imgAlt: 'aBox Agency',
        projectName: 'aBox Agency',
        projectInfoOne: 'Elevate Your Online',
        projectInfoTwo: 'Presence with aBox',
        descriptionOne: 'Visual Identity, Brand Strategy,',
        descriptionTwo: 'Brand Collateral, Customer Experience',
        categories: ['all', 'branding'],
    },
    {
        href: '/portfolio/branding/nourysh',
        onMouseOverColor: { bg: '#36386D', text: '#A6A9E9' },
        imgSrc: '/assets/image/nourysh_brand.webp',
        imgAlt: 'Nourysh',
        projectName: 'Nourysh',
        projectInfoOne: 'Science-backed focused',
        projectInfoTwo: 'on self-nourishment.',
        descriptionOne: 'Visual Identity, Brand Strategy,',
        descriptionTwo: 'Brand Collateral, Customer Experience',
        categories: ['all', 'branding'],
    },
    {
        href: '/portfolio/branding/terahash-solutions',
        onMouseOverColor: { bg: '#0F0F0F', text: '#fff' },
        imgSrc: '/assets/image/terahash_brand.webp',
        imgAlt: 'Terahash Solutions',
        projectName: 'Terahash Solutions',
        projectInfoOne: 'Innovative ASIC mining',
        projectInfoTwo: 'solutions for sustainable profitability.',
        descriptionOne: 'Visual Identity, Brand Strategy,',
        descriptionTwo: 'Brand Collateral, Customer Experience',
        categories: ['all', 'branding'],
    },



    {
        href: '/portfolio/terahash-solutions',
        onMouseOverColor: { bg: '#0F0F0F', text: '#A4A4A4' },
        imgSrc: '/assets/image/terahash.webp',
        imgAlt: 'Terahash Solutions',
        projectName: 'Terahash Solution',
        projectInfoOne: 'Best Partner for Profitable and Reliable Crypto Mining.',
        descriptionOne: 'Webflow Development, UI/UX, Logo Design, 3D Render',
        categories: ['all', 'e-commerce', 'webflow'],
    },
    {
        href: '/portfolio/codifi',
        onMouseOverColor: { bg: '#311367', text: '#A38DC9' },
        imgSrc: '/assets/image/codifi.webp',
        imgAlt: 'Codifi',
        projectName: 'Codifi',
        projectInfoOne: 'crafting cutting-edge financial solutions for Indian markets.',
        descriptionOne: 'Webflow Development, UI/UX, Logo Design, Onsite SEO',
        categories: ['all', 'webflow'],
    },
    {
        href: '/portfolio/paperclip-and-co',
        onMouseOverColor: { bg: '#040328', text: '#E8FAFF' },
        imgSrc: '/assets/image/thumbnailimage/paperclip.webp',
        imgAlt: 'paperclip & co',
        projectName: 'Paperclip & Co',
        projectInfoOne: 'Innovative Campaigns, Unleashing Social Media Power.',
        descriptionOne: 'Custom Development, UI/UX, Brand Design',
        categories: ['all', 'webflow'],
    },



    {
        href: '/portfolio/fone-network',
        onMouseOverColor: { bg: '#07083A', text: '#989AF4' },
        imgSrc: '/assets/image/fone.webp',
        imgAlt: 'Fone Network',
        projectName: 'Fone Network',
        projectInfoOne: 'Mobile blockchain & cryptocurrency enabling NFT creation, and staking.',
        descriptionOne: 'React js Development, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'custom-development'],
    },
    {
        href: '/portfolio/dl-films',
        onMouseOverColor: { bg: '#171717', text: '#F5F1EB' },
        imgSrc: '/assets/image/dlfilm.webp',
        imgAlt: 'dl films',
        projectName: 'DL Films',
        projectInfoOne: 'Explore Life Through Lens Photography Experts',
        descriptionOne: 'React js Development, UI/UX, Branding, Onsite SEO',
        categories: ['all', 'custom-development'],
    },
    {
        href: '/portfolio/aurolab',
        onMouseOverColor: { bg: '#005268', text: '#7FB3C0' },
        imgSrc: '/assets/image/aurolab.webp',
        imgAlt: 'AuroLab',
        projectName: 'AuroLab',
        projectInfoOne: 'A global leader in ophthalmic solutions, enabling better vision for all.',
        descriptionOne: 'Custome Web Development',
        categories: ['all', 'custom-development'],
    },
    {
        href: '/portfolio/fast-n-fry',
        onMouseOverColor: { bg: '#130F0F', text: '#E8FAFF' },
        imgSrc: '/assets/image/thumbnailimage/fastfry.webp',
        imgAlt: 'fast n fry',
        projectName: 'Fast n Fry',
        projectInfoOne: 'Premium Potato & Frozen Snacks Manufacturer.',
        descriptionOne: 'WordPress Development, UI/UX, Brand Design, Branding',
        categories: ['all', 'custom-development'],
    },
    {
        href: '/portfolio/greenfay',
        onMouseOverColor: { bg: '#2E0B01', text: '#E8FAFF' },
        imgSrc: '/assets/image/greenfay.webp',
        imgAlt: 'Greenfay',
        projectName: 'Greenfay',
        projectInfoOne: 'Premium potato farming with community empowerment.',
        descriptionOne: 'WordPress Development, UI/UX, Branding, Graphic Design',
        categories: ['all', 'custom-development'],
    },
    {
        href: '/portfolio/westerville',
        onMouseOverColor: { bg: '#202C39', text: '#99BEE2' },
        imgSrc: '/assets/image/westerville.webp',
        imgAlt: 'Westerville',
        projectName: 'Westerville',
        projectInfoOne: 'Expert dental care for your dream smile.',
        descriptionOne: 'WordPress Development, UI/UX, Branding',
        categories: ['all', 'custom-development'],
    },
]