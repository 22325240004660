import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";
import SiteLink from "../../component/icons/SiteLink";

export default function UIJummbo() {

      return (
            <>
                  <Helmet>
                        <title></title>
                        <meta name="title" content="" />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <link rel="canonical" href="" />
                  </Helmet>

                  <Header />

                  <div className="jummbo_bg">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod sinclairs_text2_bg ayurmedix_text">UI</p>
                                                <h1 className="services_title ayurmedix_text mb-0"><span>Growth Hive</span> <br /> <span className="jummbo_title">Designing Wellness: <br /> Crafting Health Through Design</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div className="product_banner">
                              <img src="/assets/image/ui/jummbo/jummbo1.webp"
                                    alt="Jumbo" className="img-fluid w-100" />
                        </div>

                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content ayurmedix_text">Teatox is a globally recognized brand specializing in herbal teas for everyday use. So  we’ve crafted a unique user interface that encapsulates the essence of the Teatox brand with a touch of international flair. Given that Teatox operates in the e-commerce space, we’ve prioritized user-friendliness, creating an engaging and intuitive experience for all users. This design not only reflects the brand’s identity but also enhances user interaction, making every visit to the Teatox website a delightful experience.</p>                                                
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="jummbo_title services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ayurmedix_list">
                                                                  <li>UI Design</li>
                                                                  <li>UX Design</li>
                                                                  <li>CRO</li>
                                                                  <li>Graphics Design</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border jummbo_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="jummbo_title services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list  ayurmedix_list">
                                                                  <li>Herbal and wellness teas</li>
                                                                  <li>E-commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div className="ui_section">
                              <div className="container">
                                    <div className="strenth_my_80 mt-0">
                                          <img src="/assets/image/ui/jummbo/jummbo2.webp"
                                                alt="Jumbo" className="img-fluid w-100" />
                                    </div>
                                    <img src="/assets/image/ui/jummbo/jummbo3.webp"
                                          alt="Jumbo" className="img-fluid w-100" />

                                    <div className="mt_30">
                                          <img src="/assets/image/ui/jummbo/jummbo4.webp" className="w-100" alt="Jumbo" />
                                    </div>
                                    <div className="row mt_30">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/ui/jummbo/jummbo5.webp" className="w-100" alt="Jumbo" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/ui/jummbo/jummbo6.webp" className="w-100" alt="Jumbo" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="mt_30">
                                          <img src="/assets/image/ui/jummbo/jummbo7.webp" className="w-100" alt="Jumbo" />
                                    </div>

                                    <div className="mt_20">
                                          <img src="/assets/image/ui/jummbo/jummbo8.webp" className="w-100" alt="Jumbo" />
                                    </div>
                                    <div className="row mt_30 pb-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/ui/jummbo/jummbo9.webp" className="w-100" alt="Jumbo" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/ui/jummbo/jummbo10.webp" className="w-100" alt="Jumbo" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/ui/jummbo/jummbo11.webp" className="w-100" alt="Jumbo" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}