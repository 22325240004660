import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioMia() {

    return (
        <>
            <Helmet>
                <title>MIA Shopify Development | Website for Organic Products | aBox Agency</title>
                <meta name="title" content="MIA Shopify Development | Website for Organic Products | aBox Agency" />
                <meta name="description" content="Discover how aBox enhanced MIA’s online identity with a custom Shopify website, impactful branding, and engaging graphics, focusing on organic cotton sanitary pads for comfort and safety." />
                <meta name="keywords" content="aBox Agency, website design, Shopify development, MIA, My Inner Ally Website, Shopify experts, Shopify India, organic sanitary products, branding, graphic design, user experience, period care solutions, eco-friendly pads, Figma design, digital branding, sanitary napkins, custom website solutions, women's health, product visibility, eCommerce development, health and wellness, user-friendly design, sustainable products, online store development, engaging creatives, organic cotton, hygiene products, effective branding strategies, women's hygiene products, innovative web design, online marketing, digital solutions, health products, brand identity, Shopify design, Shopify store setup, Shopify website design, Shopify customization, Shopify theme development, Shopify SEO services, Shopify app integration, Shopify eCommerce solutions, Shopify maintenance, Shopify migration services, Shopify optimization, Shopify consulting, Shopify user experience, Shopify performance enhancement, Shopify analytics, Shopify marketing strategies, Shopify store management, Shopify product management, Shopify payment integration, Shopify sales optimization, Shopify training, Shopify support, Shopify hosting services, Shopify checkout optimization, Shopify inventory management, Shopify store redesign, Shopify automation, Shopify branding" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/mia/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_mia">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name mia_text2_bg mia_text">Mia My Ally</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1739954589_Mia.mp4" type="video/mp4" />
                                    </video>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-lg-8 col-md-10">
                                    <p className="product_name mia_text2_bg writing_mod mia_text">Published in 2024</p>
                                    <h1 className="services_title mia_text mb-0">
                                        MIA - My Inner Ally <br />
                                        <span className="mia_title">
                                            Developing the presence <br className="d-xl-block d-none"/> for Period Care
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content mia_text mb-3">At aBox, we executed a comprehensive Figma design and Shopify website development, focusing on the mission to provide organic cotton sanitary pads. Our branding and graphic design emphasized comfort and safety.</p>
                                    <p className="services_content mia_text">Our team crafted engaging creatives to showcase the values and product benefits, ensuring the website reflects the commitment to absorbency and safety while enhancing user experience and brand visibility in a competitive market.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://miamyally.com/" target="_blank" className="d-flex align-items-center link_btn mia_btn_bg">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="mia_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list mia_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UIUX Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>Integration</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="mia_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list mia_list">
                                                <li>Feminine hygiene products</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_mia_logo">
                                <img src="/assets/image/portfolios/mia/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/mia/mia1.jpg" className="w-100" alt="Mia" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title mia_title">Enhancing User Experience Through branding</h2>
                                    <p className="services_pera mia_text font_regular mb-0">
                                        We elevated the online presence through refined branding and graphics, creating an intuitive Shopify website that simplifies navigation and effectively promotes organic sanitary products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-0">
                                <img src="/assets/image/portfolios/mia/mia2.jpg"
                                    alt="Mia" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 bg_transparent">
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia3.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia4.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia5.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia6.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg px-0 py-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/mia/mia7.jpg"
                                            alt="Mia" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_20">
                                        <img src="/assets/image/portfolios/mia/mia8.jpg"
                                            alt="Mia" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/mia/mia9.jpg"
                                            alt="Mia" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 bg_transparent">
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia10.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia11.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/mia/mia12.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title mia_title">Boosting Awareness for Essential Products</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera mia_text mb-0">
                                        The brand now enjoys increased customer engagement and visibility, thanks to our thoughtful design and development efforts that resonate with their mission for comfortable, safe period care. Explore the site at <a href="https://miamyally.com/" target="_blank" className="mia_title portfolio_a">MIA - My Inner Ally</a>.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80">
                                <img src="/assets/image/portfolios/mia/mia13.jpg"
                                    alt="Mia" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/mia/mia14.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mia/mia15.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mia/mia16.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mia/mia17.jpg"
                                        alt="Mia" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="strenth_my_80 mb-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title mia_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/mia/mia18.jpg" className="w-100" alt="Mia color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/mia/mia19.jpg" alt="Mia font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div class="perform_space py-5">
                    <div class="container">
                        <div class="div-block-40 flex-column"></div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg g_pulla_bg">
                    <div className="container">
                        <div className="text-center">
                            <a href="/portfolio/gpulla-reddy-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}