import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";
import SiteLink from "../../component/icons/SiteLink";

export default function UIGrowthHive() {

    return (
        <>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="" />
            </Helmet>

            <Header />

            <div className="growth_hive_bg">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod growth_text_bg">UI</p>
                                <h1 className="services_title text-white mb-0"><span>Growth Hive</span> <br /> <span className="growth_title">Designing Wellness: <br /> Crafting Health Through Design</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product_banner">
                    <img src="/assets/image/ui/growth-hive/growth-hive1.jpg"
                        alt="growth-hive" className="img-fluid w-100" />
                </div>

                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text-white">Teatox is a globally recognized brand specializing in herbal teas for everyday use. So  we’ve crafted a unique user interface that encapsulates the essence of the Teatox brand with a touch of international flair. Given that Teatox operates in the e-commerce space, we’ve prioritized user-friendliness, creating an engaging and intuitive experience for all users. This design not only reflects the brand’s identity but also enhances user interaction, making every visit to the Teatox website a delightful experience.</p>
                                {/* <ul className="official_web">
                                    <li>
                                        <a href="" target="_blank" className="d-flex align-items-center link_btn growth_hive_link">Official Website
                                            <SiteLink />
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text-white services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_growth_list">
                                            <li>UI Design</li>
                                            <li>UX Design</li>
                                            <li>CRO</li>
                                            <li>Graphics Design</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border growth_border"></div>
                                    <div className="col-md-6">
                                        <p className="text-white services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list  ui_growth_list">
                                            <li>Herbal and wellness teas</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui_section">
                    <div className="container">
                        <div className="strenth_my_80 mt-0">
                            <img src="/assets/image/ui/growth-hive/growth-hive2.jpg"
                                alt="growth-hive" className="img-fluid w-100" />
                        </div>
                        <div className="mt_20">
                            <img src="/assets/image/ui/growth-hive/growth-hive3.jpg" className="w-100" alt="growth-hive" />
                        </div>
                        <div className="mt_20">
                            <img src="/assets/image/ui/growth-hive/growth-hive4.jpg" className="w-100" alt="growth-hive" />
                        </div>
                        <div className="mt_20">
                            <img src="/assets/image/ui/growth-hive/growth-hive5.jpg" className="w-100" alt="growth-hive" />
                        </div>
                        <div className="mt_20">
                            <img src="/assets/image/ui/growth-hive/growth-hive6.jpg" className="w-100" alt="growth-hive" />
                        </div>

                        <div className="mt_20 pb-4">
                            <img src="/assets/image/ui/growth-hive/growth-hive7.jpg" className="w-100" alt="growth-hive" />
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}