import React from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../component/Header";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import DataNotFound from "../component/DataNotFound";
import Loader from "../component/Loader";
import ContactForm from "../component/ContactForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import DiscoverArrow from "../component/icons/DiscoverArrow";
import blogData from '../data/blogs'
// import InstagramEmbed from "react-instagram-embed";

export default function Home() {

    function changeColor(...args) {
        document.getElementById("project").style.backgroundColor = args[0];
        const elements = document.querySelectorAll(`.change-color`)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.color = args[1]
        }
    }

    const portfolioLeft = [
        {
            href: '/portfolio/within-beauty',
            onMouseOverColor: { bg: '#184158', text: '#61B0F1' },
            imgSrc: '/assets/image/homeportfolio/within.webp',
            imgAlt: 'Within Beauty',
            projectName: 'Within Beauty',
            projectInfoOne: 'Skincare products with natural,',
            projectInfoTwo: 'cruelty-free ingredients.',
            descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
            descriptionTwo: 'Site Maintenance',
        },
        {
            href: '/portfolio/uppercase',
            onMouseOverColor: { bg: '#063541', text: '#24A3C3' },
            imgSrc: '/assets/image/homeportfolio/uppercase.webp',
            imgAlt: 'Uppercase',
            projectName: 'Uppercase',
            projectInfoOne: 'Eco-Friendly Travel Gear',
            projectInfoTwo: 'with Stylish Design.',
            descriptionOne: 'Shopify, UI/UX, Branding, Integrations',
            descriptionTwo: 'Site Maintenance',
        },
        {
            href: '/portfolio/anand-sweets',
            onMouseOverColor: { bg: '#18302C', text: '#6BB194' },
            imgSrc: '/assets/image/homeportfolio/anand_sweets.webp',
            imgAlt: 'Anand Sweets',
            projectName: 'Anand Sweets',
            projectInfoOne: 'Traditional Indian Sweets:',
            projectInfoTwo: 'A Taste of Royal India',
            descriptionOne: 'Shopify, UI/UX, and SEO',
        },
        {
            href: '/portfolio/label/ras-beauty',
            onMouseOverColor: { bg: '#4F2D0C', text: '#DD8E17' },
            imgSrc: '/assets/image/homeportfolio/ras_beauty.webp',
            imgAlt: 'Ras Beauty',
            projectName: 'Ras Beauty',
            projectInfoOne: 'Farm-to-Face Luxury',
            projectInfoTwo: 'Skincare with Ayurvedic Essence',
            descriptionOne: 'Product Packaging, 3D Render, Product Visualization',
        },
        {
            href: '/portfolio/freshleaf',
            onMouseOverColor: { bg: '#212721', text: '#E7EEBD' },
            imgSrc: '/assets/image/homeportfolio/freshleaf.webp',
            imgAlt: 'Fresh Leaf',
            projectName: 'Fresh Leaf',
            projectInfoOne: 'Pure, handpicked teas',
            projectInfoTwo: 'for ultimate freshness.',
            descriptionOne: 'Shopify, UI/UX, Branding, Graphic Design',
        },
        {
            href: '/portfolio/bayla',
            onMouseOverColor: { bg: '#194412', text: '#97C75A' },
            imgSrc: '/assets/image/homeportfolio/bayla.webp',
            imgAlt: 'Bayla Skins',
            projectName: 'Bayla Skins',
            projectInfoOne: 'Natural and Science-Backed',
            projectInfoTwo: 'Skincare',
            descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        }
    ];

    const portfolioRight = [
        {
            href: '/portfolio/healing-hands',
            onMouseOverColor: { bg: '#042C2F', text: '#1DBAC6' },
            imgSrc: '/assets/image/homeportfolio/healing.webp',
            imgAlt: 'Healing Hands',
            projectName: 'Healing Hands',
            projectInfoOne: 'Plant-based meds for Piles',
            projectInfoTwo: 'and Constipation Relief',
            descriptionOne: 'Shopify, UI/UX, Branding, Onsite SEO',
        },
        {
            href: '/portfolio/fone-network',
            onMouseOverColor: { bg: '#12144F', text: '#3B9FE6' },
            imgSrc: '/assets/image/homeportfolio/fone.webp',
            imgAlt: 'Fone Network',
            projectName: 'Fone Network',
            projectInfoOne: 'Mobile blockchain & cryptocurrency',
            projectInfoTwo: 'enabling NFT creation, and staking.',
            descriptionOne: 'React js Development, UI/UX, Branding, Onsite SEO',
        },
        {
            href: '/portfolio/mypro',
            onMouseOverColor: { bg: '#161616', text: '#EEEEEE' },
            imgSrc: '/assets/image/homeportfolio/mypro.webp',
            imgAlt: 'My Pro Nutritions',
            projectName: 'My Pro Nutritions',
            projectInfoOne: 'Fueling fitness, wellness,',
            projectInfoTwo: 'and peak performance',
            descriptionOne: 'Shopify, UI/UX, Branding, 3D rendering',
        },
        {
            href: '/portfolio/yogabar',
            onMouseOverColor: { bg: '#1B1830', text: '#9A3CEE' },
            imgSrc: '/assets/image/homeportfolio/yogabar.webp',
            imgAlt: 'YogaBar',
            projectName: 'YogaBar',
            projectInfoOne: 'Healthy protein-based foods',
            descriptionOne: 'Shopify, UI/UX, Graphic Designing,',
            descriptionTwo: 'Site Maintenance',
        },
        {
            href: '/portfolio/paperclip-and-co',
            onMouseOverColor: { bg: '#511515', text: '#E74950' },
            imgSrc: '/assets/image/homeportfolio/paperclip.webp',
            imgAlt: 'Paperclip & Co',
            projectName: 'Paperclip & Co',
            projectInfoOne: 'Innovative Campaigns, Unleashing',
            projectInfoTwo: 'Social Media Power.',
            descriptionOne: 'Custom Development, UI/UX, Brand Design',
        }
    ];

    const ProjectOne = ({ href, onMouseOverColor, imgSrc, imgAlt, projectName, projectInfoOne, projectInfoTwo, descriptionOne, descriptionTwo }) => (
        <div className="anand-sweets">
            <a
                href={href}
                className="project-link"
                onMouseOver={() => changeColor(onMouseOverColor.bg, onMouseOverColor.text)}
                onMouseOut={() => changeColor('#222', '#efebe7')}
            >
                <img src={imgSrc} loading="lazy" alt={imgAlt} className="w-100" />
                <div className="div-block-42">
                    <p className="project-name change-color color_light">{projectName}</p>
                    <div className="line dark_line" />
                </div>
                <h2 className="project-info change-color color_light">{projectInfoOne} {projectInfoTwo}</h2>
                <p className="paragraph-13 change-color color_light">{descriptionOne} <br className="d-lg-block d-none" /> {descriptionTwo} </p>
            </a>
        </div>
    );

    const ProjectTwo = ({ href, onMouseOverColor, imgSrc, imgAlt, projectName, projectInfoOne, projectInfoTwo, descriptionOne, descriptionTwo }) => (
        <div className="anand-sweets">
            <a
                href={href}
                className="project-link"
                onMouseOver={() => changeColor(onMouseOverColor.bg, onMouseOverColor.text)}
                onMouseOut={() => changeColor('#222', '#efebe7')}
            >
                <img src={imgSrc} loading="lazy" alt={imgAlt} className="w-100" />
                <div className="div-block-42">
                    <p className="project-name change-color color_light">{projectName}</p>
                    <div className="line dark_line" />
                </div>
                <h2 className="project-info change-color color_light">{projectInfoOne} {projectInfoTwo}</h2>
                <p className="paragraph-13 change-color color_light">{descriptionOne} <br className="d-lg-block d-none" /> {descriptionTwo}</p>
            </a>
        </div>
    );

    const clients = [
        {
            clientHref: '/portfolio/yogabar',
            clientImgSrc: '/assets/image/clients/yogabar.svg',
            clientImgAlt: 'yogabar',
            clientInfoOne: 'A Nutrition-led Food',
            clientInfoTwo: 'Company',
        },
        {
            clientHref: '/portfolio/dr-vaidya',
            clientImgSrc: '/assets/image/clients/dr_vaidyas.svg',
            clientImgAlt: 'dr vaidyas',
            clientInfoOne: 'Ayurvedic Stamina and',
            clientInfoTwo: 'Health Supplements',
        }, 
        {
            clientHref: '/portfolio/uppercase',
            clientImgSrc: '/assets/image/clients/uppercase.svg',
            clientImgAlt: 'uppercase',
            clientInfoOne: 'Eco Friendly Bags',           
        }, 
        {
            clientHref: '/portfolio/technosport',
            clientImgSrc: '/assets/image/clients/technosport.svg',
            clientImgAlt: 'techno sport',
            clientInfoOne: 'Performance Active',
            clientInfoTwo: 'Sportswear and Athleisure',
        },  
        {
            clientHref: 'https://www.nolaskinsentials.com/',
            clientImgSrc: '/assets/image/clients/nola_skin.svg',
            clientImgAlt: 'Nola Skin',
            clientInfoOne: 'Vegan Skin Essentials',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/hamdard',
            clientImgSrc: '/assets/image/clients/humdard.svg',
            clientImgAlt: 'hamdard',
            clientInfoOne: 'Rooh Afza - Drink of',
            clientInfoTwo: 'the East',
        },
        {
            clientHref: '/portfolio/hyfun',
            clientImgSrc: '/assets/image/clients/hyfun.svg',
            clientImgAlt: 'hyfun',
            clientInfoOne: 'Premium Frozen Veggie',
            clientInfoTwo: 'Snacks Collection',
        },
        {
            clientHref: '/portfolio/label/ras-beauty',
            clientImgSrc: '/assets/image/clients/ras.svg',
            clientImgAlt: 'Ras Beauty',
            clientInfoOne: 'Science-backed Luxury',
            clientInfoTwo: 'Skincare',
        },
        {
            clientHref: '/portfolio/anand-sweets',
            clientImgSrc: '/assets/image/clients/anand.svg',
            clientImgAlt: 'anand sweets',
            clientInfoOne: `Royal India's Traditional`,
            clientInfoTwo: 'Sweet Delights.',
        },
        {
            clientHref: '/portfolio/om-sweets',
            clientImgSrc: '/assets/image/clients/om.svg',
            clientImgAlt: 'om sweets',
            clientInfoOne: 'Traditional sweet treats with',
            clientInfoTwo: 'casual dining',
        },
        {
            clientHref: 'https://prashantcorner.com/',
            clientImgSrc: '/assets/image/clients/prashant_cornar.svg',
            clientImgAlt: 'prashant corner',
            clientInfoOne: 'Renowned authentic Indian',
            clientInfoTwo: 'mithai and snacks',
            target: '_blank',
        },
        // {
        //     clientHref: '',
        //     clientImgSrc: '/assets/image/clients/elder.svg',
        //     clientImgAlt: 'Elder',
        //     clientInfoOne: '',
        //     clientInfoTwo: '',        
        // },
        {
            clientHref: '/portfolio/healing-hands',
            clientImgSrc: '/assets/image/clients/healing_hands.svg',
            clientImgAlt: 'healing hands',
            clientInfoOne: 'Piles, Fissure, Fistula &',
            clientInfoTwo: 'Constipation Medicines',
        },
        {
            clientHref: '/portfolio/label/smart-mop',
            clientImgSrc: '/assets/image/clients/caspian.svg',
            clientImgAlt: 'Caspian',
            clientInfoOne: `Smart Cleaning `,
            clientInfoTwo: 'Solutions',
        },
        {
            clientHref: 'https://www.outerworld.in/',
            clientImgSrc: '/assets/image/clients/outerworld.svg',
            clientImgAlt: 'Outer World',
            clientInfoOne: `Premium Party Wear`,
            clientInfoTwo: 'Clothing',
            target: '_blank',
        },
        // {
        //     clientHref: 'https://chaykra.com/',
        //     clientImgSrc: '/assets/image/clients/chaykra.svg',
        //     clientImgAlt: 'Chaykra',
        //     clientInfoOne: ` Yoga Wears and`,
        //     clientInfoTwo: 'Accessories',
        //     target: '_blank',
        // },
        {
            clientHref: 'https://www.tomahawk.tools/',
            clientImgSrc: '/assets/image/clients/tomahawk.svg',
            clientImgAlt: 'Tomahawk',
            clientInfoOne: `Durable Power Tools`,
            clientInfoTwo: 'and Hand Tools',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/savani-jewellery',
            clientImgSrc: '/assets/image/clients/savani.svg',
            clientImgAlt: 'Savani',
            clientInfoOne: `Fine Diamond`,
            clientInfoTwo: 'Jewellery',
        },
        {
            clientHref: 'https://naturali.co.in/',
            clientImgSrc: '/assets/image/clients/naturli.svg',
            clientImgAlt: 'Naturali',
            clientInfoOne: `Natural Hair & Skin Care`,            
            target: '_blank',
        },        
    ];

    const ClientsCard = ({ clientHref, target, clientImgSrc, clientImgAlt, clientInfoOne, clientInfoTwo }) => (
        <a href={clientHref} target={target} className="image_color bg-white">
            <div className="product_logo">
                <div className="logo_box">
                    <img src={clientImgSrc} loading="lazy" alt={clientImgAlt} />
                </div>
                <p className="d-md-block d-none">{clientInfoOne} <br className="d-lg-block d-none" /> {clientInfoTwo}</p>
                <a className="d-md-flex d-none logos_link" href={clientHref} target={target}>
                    <span className="discover_span">DISCOVER</span>
                    <span className="logo_span">
                        <DiscoverArrow />
                    </span>
                </a>
            </div>
        </a>
    );


    return (
        <>
            <Helmet>
                <title>Shopify Development and UI/UX Design Services | aBox Agency</title>
                <meta name="title" content="Shopify Development and UI/UX Design Services | aBox Agency" />
                <meta name="keywords" content="Web design, web development, responsive design, user experience, SEO, digital marketing, e-commerce solutions, CMS development, mobile app development, branding, graphic design, UI/UX design, web agency, custom themes, Shopify experts, website optimization, online store development" />
                <meta name="description"
                    content="Transform your online presence with expert Shopify development and stunning UI/UX designs. We offer professional web solution for you. Contact us Now." />
                <link rel="canonical" href="https://www.abox.agency/" />
                <meta property="og:title" content="aBox Agency" />
                <meta property="og:description" content="Transform your online presence with expert Shopify development and stunning UI/UX designs. We offer professional web solution for you. Contact us Now." />
                <meta property="og:image" content="https://abox.agency/assets/image/meta_og_image.jpg" />
                <meta property="og:url" content="https://abox.agency/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="aBox Agency" />
            </Helmet>

            <GoogleReCaptchaProvider
                reCaptchaKey="6LfdHy0pAAAAAKE3pfhPaTjJqt9vgHye5CGL-jZz"
            >

                <Header />

                {/* hero section */}
                <div className="header_padding">
                    <div className="section_detail hero-section">
                        <div className="container">
                            <div className="div-block-16">
                                <div className="d-flex align-items-center gap-sm-4 gap-3 mb-3">
                                    <a href="https://www.shopify.com/partners/directory/partner/niqox1" target="_blank">
                                        <div className="shopi_partner">
                                            <div>
                                                <img src="/assets/image/flag/shopi_logo.svg" alt="Shopify Partners" />
                                            </div>
                                            <div>
                                                <span>
                                                    We’re officially <br />
                                                    Shopify Partners
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="d-flex flex-md-row flex-column align-items-md-center gap-1">
                                        <div className="d-flex">
                                            <img src="/assets/image/flag/star_icon.svg" alt="5 STAR RATED" />
                                            <img src="/assets/image/flag/star_icon.svg" alt="5 STAR RATED" />
                                            <img src="/assets/image/flag/star_icon.svg" alt="5 STAR RATED" />
                                            <img src="/assets/image/flag/star_icon.svg" alt="5 STAR RATED" />
                                            <img src="/assets/image/flag/star_icon.svg" alt="5 STAR RATED" />
                                        </div>
                                        <span className="review_title">5 Star Rated</span>
                                    </div>
                                </div>
                                {/* <p className="font_medium">©2024</p> */}
                                <h1 className="title">We make brands to stand
                                    <br className="d-md-block d-none" /> out digitally differently.
                                </h1>
                            </div>
                            <div className="scroll-down">
                                <a href="#video" className="link-block-6">
                                    <p className="paragraph-2 mobile-p font_medium">Scroll<br />Down</p>
                                    <div className="div-block-25">
                                        <Player
                                            autoplay
                                            loop
                                            src="/assets/image/Scroll-Down.json"
                                            style={{ height: '50px', width: '50px', margin: '-2px 0px 0px -12px' }}
                                        >
                                            <Controls visible={true} />
                                        </Player>
                                    </div>
                                    <p className="paragraph-2 font_medium">Scroll<br />Down</p>
                                </a>
                            </div>
                            <div className="lets-talk">
                                <a href="/contact" className="link-block-5">
                                    <div className="d-md-block d-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="520" height="290" viewBox="0 0 520 290">
                                            <g id="Group_47" data-name="Group 47" transform="translate(-1400 -710)">
                                                <path id="Path_15" data-name="Path 15" d="M20,290V40H-20V0H20V40H60V80h40v40h40V80H100V40H500V290Z" transform="translate(1420 710)" fill="#aa5042" />
                                                <path id="Union_2" data-name="Union 2" d="M120,160V120h40v40ZM0,160V120H40v40Zm40-40V80H80v40Zm80-40V40H80V0h40V40h40V80Z" transform="translate(1760 840)" fill="#b86558" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="d-md-none d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="520" height="290" viewBox="0 0 520 290">
                                            <g id="Group_263" data-name="Group 263" transform="translate(0 -831.991)">
                                                <path id="Path_15" data-name="Path 15" d="M20,317.083V40H64.332V84.333h44.334v44.334H153V84.333H108.667V40H552V317.083Z" transform="translate(-20 835.917)" fill="#aa5042" />
                                                <path id="Union_2" data-name="Union 2" d="M120,160V120h40v40ZM0,160V120H40v40Zm40-40V80H80v40Zm80-40V40H80V0h40V40h40V80Z" transform="translate(372 993)" fill="#b86558" />
                                                <path id="Path_56" data-name="Path 56" d="M-.316-.009h44v44h-44Z" transform="translate(134 832)" fill="#aa5042" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="link_content">
                                        <p className="paragraph-9">Increase and improve how easily <br />people can find you online.</p>
                                        <div className="div-block-19">
                                            <h2 className="heading-5">Let's Talk</h2>
                                            <img src="/assets/image/arrow.svg" loading="lazy" alt="arrow" className="arrow" />
                                            <img src="/assets/image/arrow.svg" loading="lazy" alt="arrow" className="arrow_hover" />
                                        </div>
                                        <div className="div-block-21" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* video section */}
                <div id="video" className="section_detail video-section">
                    <div className="container-fluid ps-0">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-6 pe-0">
                                <div className="video">
                                    <video autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894305.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="col-xxl-7 col-xl-6">
                                <div className="div-block-7">
                                    <div className="icon"><img src="/assets/image/icon.svg" loading="lazy" alt="icon" /></div>
                                    <p className="paragraph-6">At ABox, we enjoy being your digital partners and paving the way for your brand's online success. We are here to help you bring your vision to life, from concept to execution.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* service section */}
                <div className="section_detail service-section">
                    <div className="container">
                        <div>
                            <p className="paragraph-7">Our Capabilities</p>
                            {/* Shopify service */}
                            <div className="service_detail">
                                <a href="/services/shopify" className="service_link">
                                    <div className="text-block-5">Shopify</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Shopify</span>
                                            <span className="text-block-4">Shopify plus</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">WooCommerce</span>
                                            <span className="text-block-4">Custom</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* UI/UX service */}
                            <div className="service_detail">
                                <a href="/services/ui-ux" className="service_link">
                                    <div className="text-block-5">UI/UX</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">User Interface</span>
                                            <span className="text-block-4">User Experience</span>
                                            <span className="text-block-4">UI Design</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Wireframes</span>
                                            <span className="text-block-4">Icons</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Visual Identity service */}
                            <div className="service_detail">
                                <a href="/services/visual-identity" className="service_link">
                                    <div className="text-block-5">Visual Identity</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Research</span>
                                            <span className="text-block-4">Logo Design</span>
                                            <span className="text-block-4">Color Palettes</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Typography</span>
                                            <span className="text-block-4">Digital Assets</span>
                                            <span className="text-block-4">Guidelines</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Webflow service*/}
                            <div className="service_detail">
                                <a href="#" className="service_link">
                                    <div className="text-block-5">Webflow</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Design & Development</span>
                                            <span className="text-block-4">Maintenance</span>
                                            <span className="text-block-4">Website Redesign</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Migration</span>
                                            <span className="text-block-4">E-commerce Store</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Product Design service*/}
                            <div className="service_detail">
                                <a href="#" className="service_link">
                                    <div className="text-block-5">Product Design</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Product Strategy</span>
                                            <span className="text-block-4">Product Presentation</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Product 3D</span>
                                            <span className="text-block-4">Packaging Design</span>
                                            <span className="text-block-4">Industrial Design</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Web Design service */}
                            <div className="service_detail">
                                <a href="/services/web-design" className="service_link">
                                    <div className="text-block-5">Web Design</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Fast Load</span>
                                            <span className="text-block-4">Responsive</span>
                                            <span className="text-block-4">Cross Browser</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Typography</span>
                                            <span className="text-block-4">SEO Consideration</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* 3d render service */}
                            <div className="service_detail">
                                <a href="/services/3d-render" className="service_link">
                                    <div className="text-block-5">3D Render</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">3D Product</span>
                                            <span className="text-block-4">Modeling</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Texturing</span>
                                            <span className="text-block-4">Post-production</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Development service */}
                            <div className="service_detail">
                                <a href="/services/web-development" className="service_link">
                                    <div className="text-block-5">Development</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Performance</span>
                                            <span className="text-block-4">Security</span>
                                            <span className="text-block-4">Testing</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Deployment</span>
                                            <span className="text-block-4">Maintenance</span>
                                            <span className="text-block-4">Cross Browser</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* Onsite SEO service */}
                            <div className="service_detail">
                                <a href="/services/seo" className="service_link">
                                    <div className="text-block-5">Onsite SEO</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Onpage SEO</span>
                                            <span className="text-block-4">Meta Tags</span>
                                            <span className="text-block-4">URL Structure</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Architecture</span>
                                            <span className="text-block-4">Keywords</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* For Startup service */}
                            <div className="service_detail">
                                <a href="/services/for-startup" className="service_link">
                                    <div className="text-block-5">For Startup</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Research</span>
                                            <span className="text-block-4">Strategy</span>
                                            <span className="text-block-4">Branding</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Digital Solution</span>
                                            <span className="text-block-4">Marketing</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg" />
                            </div>
                            {/* A/B Testing service */}
                            <div className="service_detail border-red">
                                <a href="/services/ab-testing" className="service_link">
                                    <div className="text-block-5 tab_color">A/B Testing</div>
                                    <div className="service">
                                        <div className="d-flex align-items-center justify-content-xl-end justify-content-start">
                                            <span className="text-block-4 margin-0">Test Element</span>
                                            <span className="text-block-4">Rendomization</span>
                                            <span className="text-block-4">Testing Tool</span>
                                        </div>
                                        <div className="div-block-13">
                                            <span className="text-block-4 margin-0">Data Analysis</span>
                                            <span className="text-block-4">Continuous Testing</span>
                                        </div>
                                    </div>
                                </a>
                                <div className="service-bg bg-red" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* hire section */}
                <div className="section_detail hire-section">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-8">
                                <p className="paragraph-10">Our super-successful development strategy, embraced by 65 awesome businesses, has set off a digital sensation! It's like our clients' brands are throwing the ultimate party, and 10 million people are invited!</p>
                                <div className="div-block-29">
                                    <a href="/contact" className="link-block-7">
                                        Way to go
                                        <img src="/assets/image/right_arrow.svg" loading="lazy" alt="right arrow" className="image-4" />
                                    </a>
                                    <div className="div-block-30" />
                                    <div className="div-block-31" />
                                    <div className="div-block-32" />
                                    <div className="div-block-33"><img src="/assets/image/crown.svg" loading="lazy" alt="crown" /></div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <a href="/contact" className="hire_link">
                                    {/* <img src="assets/image/left-top.svg" loading="lazy" alt="left top arrow" className="image-5 hire_arrow" />
                            <img src="assets/image/right-top.svg" loading="lazy" alt="right top arrow" className="image-6" />
                            <div className="line_div">
                                <div className="cross_line" />
                            </div>
                            <img src="assets/image/left-bottom.svg" loading="lazy" alt="left bottom arrow" className="image-7 hire_arrow" />
                            <img src="assets/image/right-bottom.svg" loading="lazy" alt="right bottom arrow" className="image-8 hire_arrow" />
                            <div className="div-block-35">
                                <div className="text-block-6">hire us</div>
                            </div> */}
                                    <svg id="Component_20_2" data-name="Component 20 – 2" xmlns="http://www.w3.org/2000/svg" width={200} height={200} viewBox="0 0 200 200">
                                        <g id="Ellipse_257" data-name="Ellipse 257" transform="translate(0 0)" fill="none" stroke="#222" strokeWidth={1}>
                                            <circle cx={100} cy={100} r={100} stroke="none" />
                                            <circle cx={100} cy={100} r="99.5" fill="none" />
                                        </g>
                                        <g id="Ellipse_259" data-name="Ellipse 259" transform="translate(25 0)" fill="none" stroke="#222" strokeWidth={1}>
                                            <ellipse cx={75} cy={100} rx={75} ry={100} stroke="none" />
                                            <ellipse cx={75} cy={100} rx="74.5" ry="99.5" fill="none" />
                                        </g>
                                        <g id="Ellipse_260" data-name="Ellipse 260" transform="translate(54 0)" fill="none" stroke="#222" strokeWidth={1}>
                                            <ellipse cx={46} cy={100} rx={46} ry={100} stroke="none" />
                                            <ellipse cx={46} cy={100} rx="45.5" ry="99.5" fill="none" />
                                        </g>
                                        <g id="Ellipse_261" data-name="Ellipse 261" transform="translate(118 0) rotate(90)" fill="none" stroke="#222" strokeWidth={1}>
                                            <ellipse cx={100} cy={18} rx={100} ry={18} stroke="none" />
                                            <ellipse cx={100} cy={18} rx="99.5" ry="17.5" fill="none" />
                                        </g>
                                        <g id="Ellipse_258" data-name="Ellipse 258" transform="translate(0 70)" fill="#fff" stroke="#222" strokeWidth={1}>
                                            <ellipse cx={100} cy={30} rx={100} ry={30} stroke="none" />
                                            <ellipse cx={100} cy={30} rx="99.5" ry="29.5" fill="none" />
                                        </g>
                                        <text id="Hire_Us" data-name="Hire Us" transform="translate(51.657 109.433)" fill="#222" fontSize={35} fontFamily="Ubermove" fontWeight={400}><tspan x={0} y={0}>hire us</tspan></text>
                                        <path id="Subtraction_6" data-name="Subtraction 6" d="M-1601.017-3392.768h-2.047V-3409.2H-1619.5v-2.047h18.482v18.481Z" transform="translate(1801.017 3411.25)" fill="#222" />
                                        <path id="Subtraction_5" data-name="Subtraction 5" d="M-1617.2-3392.768h-2.047v-18.482h18.483v2.047H-1617.2v16.434Z" transform="translate(1619.25 3411.25)" fill="#222" />
                                        <path id="Subtraction_4" data-name="Subtraction 4" d="M-1600.768-3393.017h-18.482V-3411.5h2.047v16.434h16.435v2.048Z" transform="translate(1619.25 3593.017)" fill="#222" />
                                        <path id="Subtraction_3" data-name="Subtraction 3" d="M-1601.518-3393.518H-1619.5v-1.547h16.435V-3411.5h1.547v17.981Z" transform="translate(1801.518 3593.518)" fill="#222" />
                                        <path id="Path_51" data-name="Path 51" d="M159.887,1.185l-17.3,17.3" transform="translate(39.057 0)" fill="none" stroke="#222" strokeWidth={2} />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-section bg-white border_top">
                    <div className="container">
                        <div className="d-flex flex-md-row flex-column align-items-md-center gap_100">
                            <h3 className="product_title white_space mb-0">Our Partners</h3>
                            <span className="h_border d-md-block d-none"></span>
                            <div className="overflow-hidden">
                                <div className="partners_marquee">
                                    <a href="#">
                                        <img src="/assets/image/partner/shopify.svg" alt="shopify" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/phonepe.svg" alt="PhonePe" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/gokwik.svg" alt="Go Kwik" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/interakt.svg" alt="Interakt" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/business.svg" alt="Business on Bot" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/unicommerce.svg" alt="Unicommerce" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/checkout.svg" alt="shiprocket Checkout" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/paywhirl.svg" alt="PayWhirl" />
                                    </a>
                                    <a href="#">
                                        <img src="/assets/image/partner/returnprime.svg" alt="Return Prime" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* project section */}
                <div id="project" className="section_detail project-section">
                    <div className="container">
                        <div className="row project-detail">
                            <div className="col-lg-6">
                                <h2 className="heading-6 change-color">Amazing projects we've been working on!</h2>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <p className="paragraph-11 font_light change-color">Our projects are a testament to our commitment and client happiness. Let our work do the talking!</p>
                            </div>
                        </div>
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-lg-6 space_right">
                                    <div className="projects me-0">
                                        {
                                            portfolioLeft.map((i, index) =>
                                                <ProjectOne key={index} {...i} />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 space_left">
                                    <div className="projects margin-top me-0">
                                        {
                                            portfolioRight.map((i, index) =>
                                                <ProjectTwo key={index} {...i} />
                                            )
                                        }
                                        <div className="anand-sweets">
                                            <div className="project-link">
                                                <h3 className="service_h2 color_light change-color">
                                                    There's more <br className="d-lg-block d-none" />
                                                    stories to see
                                                </h3>
                                                <a href="/portfolio" className="btn submit_btn all_portfolio mt-4">
                                                    View all projects
                                                    <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                                    </svg>
                                                    <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* seven plus section */}
                <div className="experience">
                    <div className="container">
                        <div className="position-relative">
                            <div className="approach">
                                <div className="approach_div">
                                    <p className="sevenplus_p mb-md-4 mb-3">Been in the game</p>
                                    <h2 className="sevenplus_h2">Seven<sup className="color_dark">+</sup><br /> years</h2>
                                </div>
                                <div className="partnership">
                                    <p className="sevenplus_detail">Transform your concepts into an outstanding product with our proficient design and development. We’re with you at every juncture on your journey to ultimate victory!</p>
                                </div>
                            </div>
                            <div className="svg_pattern">
                                <svg className="d-none d-md-block" xmlns="http://www.w3.org/2000/svg" width="1720" height="727" viewBox="0 0 1720 727" fill="none">
                                    <line opacity="0.3" x1="763" y1="538.5" x2="896" y2="538.5" stroke="#222222" />
                                    <line opacity="0.3" x1="764" y1="506.5" x2="814" y2="506.5" stroke="#222222" />
                                    <line opacity="0.3" x1="796" y1="522.5" x2="814" y2="522.5" stroke="#222222" />
                                    <line opacity="0.3" x1="763.5" y1="537.996" x2="764.21" y2="456.996" stroke="#222222" />
                                    <line opacity="0.3" x1="813.5" y1="537.996" x2="814.21" y2="456.996" stroke="#222222" />
                                    <line opacity="0.3" x1="796.5" y1="538" x2="796.5" y2="506" stroke="#222222" />
                                    <circle cx="1617.5" cy="354.714" r="51.75" stroke="#417B5A" stroke-width="1.5" />
                                    <line opacity="0.3" x1="895.5" y1="727" x2="895.5" y2="-2.18557e-08" stroke="#222222" />
                                    <line opacity="0.3" x1="4.37114e-08" y1="456.714" x2="1720" y2="456.714" stroke="#222222" />
                                    <rect x="1546.75" y="283.964" width="27.5" height="27.5" stroke="#417B5A" stroke-width="1.5" />
                                    <path d="M1452.98 669.977C1452.98 361.825 1203.35 112.022 895.405 112.022" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M551.022 456.641C551.022 266.313 705.207 112.022 895.405 112.022" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M551.022 456.641C551.022 574.465 646.468 669.977 764.212 669.977" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M895.405 538.695C895.405 611.198 836.666 669.978 764.212 669.978" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M895.405 538.695C895.405 493.373 858.698 456.641 813.407 456.641" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M764.212 505.869C764.212 478.688 786.245 456.641 813.407 456.641" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M764.212 505.869C764.212 523.99 778.887 538.695 797.016 538.695" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M813.407 522.271C813.407 531.332 806.07 538.674 797.016 538.674" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <rect x="896" y="457" width="824" height="215" fill="white" />
                                </svg>
                                <svg className="d-block d-md-none" xmlns="http://www.w3.org/2000/svg" width="451" height="757" viewBox="0 0 451 757" fill="none">
                                    <line opacity="0.3" y1="-0.5" x2="86.45" y2="-0.5" transform="matrix(1.30477e-07 -1 -1 -1.30477e-07 160.425 589.05)" stroke="#222222" />
                                    <line opacity="0.3" y1="-0.5" x2="32.5" y2="-0.5" transform="matrix(1.31134e-07 -1 -1 -1.31134e-07 181.225 588.4)" stroke="#222222" />
                                    <line opacity="0.3" y1="-0.5" x2="11.7" y2="-0.5" transform="matrix(1.31134e-07 -1 -1 -1.31134e-07 170.825 567.6)" stroke="#222222" />
                                    <line opacity="0.3" y1="-0.5" x2="52.652" y2="-0.5" transform="matrix(0.999962 -0.0087646 -0.0087646 -0.999962 161.075 588.4)" stroke="#222222" />
                                    <line opacity="0.3" y1="-0.5" x2="52.652" y2="-0.5" transform="matrix(0.999962 -0.0087646 -0.0087646 -0.999962 161.075 555.9)" stroke="#222222" />
                                    <line opacity="0.3" y1="-0.5" x2="20.8" y2="-0.5" transform="matrix(1 8.74228e-08 8.74228e-08 -1 161.075 566.95)" stroke="#222222" />
                                    <circle cx="34.125" cy="34.125" r="33.375" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 314.336 97.25)" stroke="#417B5A" stroke-width="1.5" />
                                    <line opacity="0.3" x1="4.37114e-08" y1="502.5" x2="451" y2="502.5" stroke="#222222" />
                                    <line opacity="0.3" x1="213.5" y1="757" x2="213.5" y2="-2.18557e-08" stroke="#222222" />
                                    <rect x="-0.75" y="-0.75" width="17.35" height="17.35" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 325.186 108.1)" stroke="#417B5A" stroke-width="1.5" />
                                    <path d="M75.2901 140.564C275.589 140.564 437.961 302.825 437.961 502.987" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M213.959 726.836C337.672 726.836 437.961 626.615 437.961 502.987" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M213.959 726.835C137.373 726.835 75.2902 664.795 75.2902 588.262" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M160.623 502.987C113.496 502.987 75.2898 541.167 75.2898 588.262" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M160.624 502.987C190.083 502.987 213.959 526.846 213.959 556.285" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M181.96 588.261C199.628 588.261 213.959 573.94 213.959 556.285" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M181.96 588.262C170.182 588.262 160.624 578.723 160.624 566.939" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                    <path d="M171.299 556.285C165.41 556.285 160.637 561.054 160.637 566.939" stroke="#AA5042" stroke-width="1.5" stroke-miterlimit="10" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="process">
                    <div className="container">
                        <div className="position-relative">
                            <a href="#" className="big_btn mx-auto">Show me the process</a>
                            <div className="border_multi">
                                <svg xmlns="http://www.w3.org/2000/svg" width={1748} height={670} viewBox="0 0 1748 670" fill="none">
                                    <rect x="230.5" y="230.5" width={1287} height={209} rx="104.5" stroke="#222222" strokeOpacity="0.3" />
                                    <rect x="130.5" y="130.5" width={1487} height={409} rx="199.5" stroke="#222222" strokeOpacity="0.2" />
                                    <rect x="0.5" y="0.5" width={1747} height={669} rx="334.5" stroke="#222222" strokeOpacity="0.1" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {/* testimonial section */}
                <div className="section_detail testimonial_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7">
                                <h2 className="testimonial_title">Hear from Delighted Clients</h2>
                                <img src="/assets/image/quote.svg" loading="lazy" alt="quote" className="mb-md-5 mb-4 quote_img" />
                            </div>
                        </div>
                        <Swiper
                            slidesPerView={1}
                            autoHeight={true}
                            speed={1500}
                            spaceBetween={'10px'}
                            // autoplay={{
                            //     delay: 2000,
                            //     disableOnInteraction: false,
                            // }}
                            autoplay={false}
                            navigation={true}
                            pagination={false}
                            draggable={true}
                            modules={[Navigation, Pagination, Autoplay]}
                            className="mySwiper h-auto"
                        >
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">Box (Niqox) played a pivotal role in launching a groundbreaking product known for excellence. They grasp our industry and business goals, emphasizing empathy and craftsmanship to create user-centric designs. Collaborating with Box (Niqox) and their talented team has been a pleasure, resulting in a true masterpiece.</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Ankush Dadu</h3>
                                            <p className="testimonial_post">Founder | Anand Sweets</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/anandsweets.svg" loading="lazy" alt="anandsweets" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">Working with Box (Niqox) has been a joy. They understood our brand perfectly and made a beautiful website. Their technical skills and dedication to our vision made it a fantastic online shopping experience. We highly recommend Box (Niqox) for their professionalism and for going above and beyond our expectations.</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Ahmed Hameed</h3>
                                            <p className="testimonial_post">CEO | Hamdard</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/hamdard.svg" loading="lazy" alt="hamdard" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">Working with Thenga Coco has been a joy. They perfectly captured our brand's essence while creating a visually stunning website. Their technical skills and commitment to our vision resulted in an exceptional shopping experience for our eco-conscious customers. We wholeheartedly recommend Thenga Coco for their professionalism and their ability to exceed expectations.</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Maria Kuriakose</h3>
                                            <p className="testimonial_post">Founder | Thenga Coco</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/thenga_coco.png" loading="lazy" alt="thenga coco" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">When our previous tech partner backed out just before our another brand launch, Niqox stepped in admirably. They not only delivered the project within an impressive SLA timeframe but also provided a flawless website at half the cost. We then gave our second brand Naturali to Abox Agency(Niqox) to completely revamp it from scratch. Once again they have proven themself and delivered a flawless website. Niqox's attentive approach to our needs and swift actions distinguish them as true tech enablers, unlike the overhyped alternatives. We are immensely grateful for their outstanding service and steadfast support, setting a new standard of excellence in eCommerce technology. We highly recommend Abox Agency(Niqox). I would also recommends any one not to go for any other ecommerce tech enablers tech agency which Shopify has partnered with</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Deepak Pant</h3>
                                            <p className="testimonial_post">VP & Business Head | Naturali by RPSG Group</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/naturali.svg" loading="lazy" alt="naturali" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p mb-4">We have worked with multiple agencies and so far Abox Agency(Niqox) has been the best.</p>
                                        <p className="testimonial_p mb-0">While we were working on a multi millionaire brand, our previous tech agency (who claim to be the ecommerce tech enablers based out of Banglore) backed out just before the launch. Abox Agency(Niqox) stepped in and delivered the project within a commendable SLA time</p>
                                        <p className="testimonial_p mb-4">Niqox  delivered a flawless website in half the cost of the previous agency we were working with. The best quality of Niqox is that they listen to your specific requirements and provide the best service as per that.</p>
                                        <p className="testimonial_p">For me Niqox leads the pack when it comes to tech requirements with their tenacious approach and quick action. In our experience, Niqox is the real tech enabler and not the overhyped agency that Shopify recommends highly.</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Deepak Pant</h3>
                                            <p className="testimonial_post">VP & Business Head | Within by RPSG Group</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/within.svg" loading="lazy" alt="within" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">ABox and Niqox was engaged to build my shopify store from scratch and also design my product labels. I must say it has been a pleasure working with them and leveraging their skill set for growth. Highly recommend them.</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Bharat</h3>
                                            <p className="testimonial_post">Founder | Herbal Hermit</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/herbal.svg" loading="lazy" alt="hearbal" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">"aBox Agency has demonstrated exceptional capabilities in brand and e-commerce development."</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Tathagat Kawshik</h3>
                                            <p className="testimonial_post">Director & CEO | Healing Hands & Herbs Pvt. Ltd.</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/healign_hands.svg" loading="lazy" alt="healign hands" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <p className="testimonial_p">"Everything was far and beyond our expectations!"</p>
                                    </div>
                                    <div className="col-xl-7 d-flex align-items-md-center flex-md-row flex-column">
                                        <div>
                                            <h3 className="testimonial_h3">Ivan Likov</h3>
                                            <p className="testimonial_post">Founder & CEO | Fone Network</p>
                                        </div>
                                        <div className="d-flex align-items-center mt-lg-0 mt-3">
                                            <div className="testimonial_line"></div>
                                            <img src="/assets/image/fone_network.svg" loading="lazy" alt="fone network" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                {/* client section */}
                <div className="section_detail client_padding">
                    <div className="container">
                        <div>
                            <h3 className="product_title">What we work with</h3>
                        </div>
                        <div className="product_section">
                            {
                                clients.map((i, index) =>
                                    <ClientsCard key={index} {...i} />
                                )
                            }
                            <div className="product_logo">
                                <h4 className="logo_h4">We back companies. <br /> But we believe in <br /> humans.</h4>
                                <div className="logo_btn">
                                    <a href="/clients" className="btn submit_btn">View More
                                        <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                        </svg>
                                        <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Featured News Section */}
                <div className="section_detail news_section">
                    <div className="container">
                        <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-end align-items-strat">
                            <div>
                                <h2 className="testimonial_title mb-0">Featured <br className="d-md-block d-none" /> Blogs</h2>
                            </div>
                            <div className="d-flex logo_btn mt-md-0 mt-4">
                                <a href={'/blogs'} className="btn submit_btn">Blogs Overview
                                    <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                    </svg>
                                    <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        {blogData ? blogData.length ?
                            blogData?.slice(0, 2).map((i, index) =>
                                <a key={index} href={`/blogs/${i.urlTitle}`} className='text-decoration-none'>
                                    <hr className="news_line" />
                                    <div className="news_box">
                                        <div className="position-relative d-inline-block w-100">
                                            <img src={i.image} className="news_img" alt={i.title} loading="lazy" />
                                            <div className="news_link w-100">
                                                <div className="d-flex justify-content-between align-items-center news_padding w-100">
                                                    <div className="insight_btn">
                                                        <p className="news_btn">{i.category}</p>
                                                    </div>
                                                    <div className="new-date">
                                                        {/* <span>5 Min ago</span>
                                                            <span className="hr_border"></span> */}
                                                        <span>{i.time}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-xl-row flex-column justify-content-between align-items-center">
                                                    <div className="news_title">
                                                        <h2>{i.title}</h2>
                                                    </div>
                                                    <div className="news_title">
                                                        <p>{i.subTitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                            : <DataNotFound />
                            : <Loader />
                        }
                    </div>
                </div>

                {/* <InstagramEmbed
                    url='https://www.instagram.com/aboxagency/?igshid=MzRlODBiNWFlZA%3D%3D'
                    clientAccessToken='123|456'
                    maxWidth={320}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => { }}
                    onSuccess={() => { }}
                    onAfterRender={() => { }}
                    onFailure={() => { }}
                /> */}

                <ContactForm />

                <LetsTalk />

                <Footer />

            </GoogleReCaptchaProvider>
        </>
    );
}