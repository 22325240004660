import React from "react";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";
import Header from "../component/Header";

export default function PortfolioShiftEco() {

      return (
            <>
                  <Helmet>
                        <title></title>
                        <meta name="title" content="" />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <link rel="canonical" href="" />
                  </Helmet>

                  <Header/>

                  <div className="page_bg bg_shift_eco">
                        <div className="header_padding">
                              {/* hero section */}
                              <div className="section_detail">
                                    <div className="container-fluid p-0">
                                          <div className="d-xl-flex">
                                                <div className="product_space">
                                                      <p className="product_name shifteco_text2_bg text-white">shift eco</p>
                                                </div>
                                                <div className="w-100 line_h_0">
                                                      {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/G.Pulla_Reddy_Sweets.mp4" type="video/mp4" />
                                    </video>*/}
                                                      <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* detail section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-12">
                                                      <p className="product_name shifteco_text2_bg text-white writing_mod">Published in 2025</p>
                                                      <h1 className="services_outerworld shifteco_title mb-0">
                                                            Shift Eco<br />
                                                            <span className="shifteco_text">
                                                                  How a Luxury Party Wear Brand Skyrocketed Revenue with a Hyperfast Shopify Store
                                                            </span>
                                                      </h1>
                                                </div>
                                                <div className="vr_border outer_border"></div>
                                                <div className="col-xl-7 pad_right">
                                                      <p className="services_content shifteco_text">The client envisioned a high-end luxury party wear brand, requiring a Shopify store that exudes elegance and sophistication. The goal was to create a clean, luxurious design paired with an intuitive user experience to reflect the brand’s premium positioning. The store needed to cater to a discerning audience while ensuring seamless navigation and a visually stunning interface.</p>
                                                      <ul className="official_web">
                                                            <li>
                                                                  <a href="#" target="_blank" className="d-flex align-items-center link_btn shifteco_link text-white">Official Website
                                                                        <SiteLink />
                                                                  </a>
                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="col-xl-5">
                                                      <div className="row">
                                                            <div className="col-md-6">
                                                                  <p className="shifteco_text services_menu">Services</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list shifteco_list">
                                                                        <li>Ecommerce</li>
                                                                        <li>Shopify</li>
                                                                        <li>UIUX</li>
                                                                        <li>CRO</li>
                                                                        <li>Consultation</li>
                                                                  </ul>
                                                            </div>
                                                            <div className="menu_list_border outer_border"></div>
                                                            <div className="col-md-6">
                                                                  <p className="shifteco_text services_menu">Industries</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list shifteco_list">
                                                                        <li>Luxury Party Wear</li>
                                                                  </ul>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Logo section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="bg_banner d-flex justify-content-center align-items-center shifteco_banner">
                                                <img src="/assets/image/portfolios/shift-eco/logo.svg" className="banner_logo" alt="Logo" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column gap_20">
                                          <div>
                                                <img src="/assets/image/portfolios/shift-eco/shift-eco1.webp" className="w-100" alt="shift-eco" />
                                          </div>
                                          <div>
                                                <img src="/assets/image/portfolios/shift-eco/shift-eco2.webp" className="w-100" alt="shift-eco" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* content Section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row justify-content-center">
                                                <div className="col-lg-8 col-md-10">
                                                      <h2 className="brand_title shifteco_title">Project Goals</h2>
                                                      <p className="services_pera shifteco_text font_regular mb-0">
                                                            Ensure high site speed and consistent uptime for a seamless shopping experience.
                                                            Develop a scalable, robust information architecture to accommodate a large catalog of luxury products.
                                                            Implement bundle, cross-sell, and upsell features to increase average order value (AOV).
                                                            Streamline communication for shipping, returns, and exchanges to enhance customer satisfaction.
                                                            Design a mobile-first interface for an optimal experience across all devices.
                                                            Boost conversion rates by simplifying the checkout process and enhancing overall user experience.

                                                      </p>
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                                <div className="gaps gap_20 gaps_columns">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco3.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <div>
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco4.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco5.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco6.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco7.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>

                                                </div>
                                          </div>
                                          <div className="strenth_my_80">
                                                <div className="row justify-content-center">
                                                      <div className="col-lg-9 col-md-10">
                                                            <h2 className="brand_title shifteco_title">Services We Provided</h2>
                                                            <div className="d-flex flex-column gap_25">
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Research:</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Conducted in-depth market and competitor analysis to align the store with luxury brand standards.</p>
                                                                  </div>
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">UI/UX Design:</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Created a clean, luxurious, and user-friendly interface tailored to the brand’s identity.</p>
                                                                  </div>
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Shopify Custom Development:</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Built a fully customized Shopify store with advanced functionality.</p>
                                                                  </div>
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">3rd Party Service Integration:</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Seamlessly integrated third-party tools for payments, shipping, and analytics.</p>
                                                                  </div>
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Consultation:</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Provided expert DTC consultancy to optimize the brand’s online presence and strategy.</p>
                                                                  </div>
                                                                  <div>
                                                                        <p className="services_text font_medium line_h_noramal shifteco_text mb-1">CRO (Conversion Rate Optimization):</p>
                                                                        <p className="services_text shifteco_text line_h_noramal mb-0">Implemented strategies to improve user engagement and drive higher conversions.</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 bg_transparent">
                                                <div className="gaps gap_20 gaps_columns">
                                                      <div>
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco8.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco9.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco10.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />

                                                </div>

                                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco11.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <div className="d-flex flex-column gap_20">
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco12.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                            <img src="/assets/image/portfolios/shift-eco/shift-eco13.webp"
                                                                  alt="shift-eco" className="img-fluid w-100" />
                                                      </div>
                                                </div>
                                                <div className="gaps gap_20 gaps_columns mt_20">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco14.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                </div>
                                                <div className="aumex sweet_delect shifteco_banner banner_space mt_20">
                                                      <div className="gaps gap_20">
                                                            <div>
                                                                  <img src="/assets/image/portfolios/shift-eco/shift-eco15.webp"
                                                                        alt="shift-eco" className="img-fluid w-100 radius_15" />
                                                            </div>
                                                            <div>
                                                                  <img src="/assets/image/portfolios/shift-eco/shift-eco16.webp"
                                                                        alt="shift-eco" className="img-fluid w-100 radius_15" />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Digital brand section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                      <p className="brand_semi_title shifteco_title">Results</p>
                                                      <div className="d-flex flex-column gap-30">
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Improved Site Performance:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">Achieved a 40% increase in site speed, ensuring faster load times and a smoother user experience.</p>
                                                            </div>
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Higher Conversion Rates:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">Streamlined checkout and mobile-first design led to a 25% boost in conversion rates.</p>
                                                            </div>
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Increased AOV:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">Bundle, cross-sell, and upsell strategies contributed to a 15% rise in average order value.</p>
                                                            </div>
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Enhanced User Engagement:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">The intuitive UX design and robust information architecture reduced bounce rates by 20%.</p>
                                                            </div>
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Seamless Operations:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">Integrated third-party tools improved shipping, returns, and exchange processes, resulting in a 30% increase in customer satisfaction.</p>
                                                            </div>
                                                            <div>
                                                                  <p className="services_text font_medium line_h_noramal shifteco_text mb-1">Scalable Growth:</p>
                                                                  <p className="services_text shifteco_text line_h_noramal mb-0">The store’s scalable architecture now supports a growing catalog, positioning the brand for long-term success.</p>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                                <div className="gaps gaps_columns">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco17.webp"
                                                            alt="Outerworl" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div className="section_space pt-0">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco18.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco19.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco20.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco21.webp"
                                                            alt="shift-eco" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Color Palette section */}
                        <div className="container">
                              <div className="div-block-40 flex-column">
                                    <h3 className="palette_title shifteco_title">Color Palette</h3>
                                    <div>
                                          <img src="/assets/image/portfolios/shift-eco/shift-eco22.webp" className="w-100" alt="shift-eco color palette" />
                                    </div>
                              </div>
                        </div>

                        {/* Playfair section */}
                        <div className="section_padd">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-12">
                                                      <img src="/assets/image/portfolios/shift-eco/shift-eco23.webp" alt="shift-eco font" className="w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Testimonial section */}
                        <div className="perform_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                      <p className="shifteco_text perform_pera">Abox agency is very good with their work. What I specifically like about their team is the agility towards action and incorporating all the feedback. They have a very good sops towards project completion and I recommend anyone who wants to build a world class website without breaking their bank to reach to ABOX</p>
                                                      <h3 className="title_color padd_s shifteco_title">Punit & Niharika</h3>
                                                      <p className="font_medium services_pera shifteco_text mb-0 padd_s">Founder of Outerworld</p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* other project */}
                        <div className="next_project_bg maisondejarin_bg">
                              <div className="container">
                                    <div className="text-center">                                         
                                          <a href="" className="next_button font_regular">Next</a>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <Footer />
            </>
      );
}