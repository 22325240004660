import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioSinclairsHotels() {

      return (
            <>
                  <Helmet>
                        <title></title>
                        <meta name="title" content="" />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <link rel="canonical" href="" />
                  </Helmet>

                  <Header />

                  <div className="page_bg bg-white">
                        <div className="header_padding">
                              {/* hero section */}
                              <div className="section_detail">
                                    <div className="container-fluid p-0">
                                          <div className="d-xl-flex">
                                                <div className="product_space">
                                                      <p className="product_name sinclairs_text2_bg ayurmedix_text">Wobb India</p>
                                                </div>
                                                <div className="w-100 line_h_0">
                                                      {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/G.Pulla_Reddy_Sweets.mp4" type="video/mp4" />
                                    </video>*/}
                                                      <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* detail section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                                      <p className="product_name sinclairs_text2_bg ayurmedix_text writing_mod">Published in 2025</p>
                                                      <h1 className="services_title teatox_text mb-0">
                                                            Great Old Days <br />
                                                            <span className="sinclairs_title">
                                                                  Revamping a Century-Old Legacy with Modern Design
                                                            </span>
                                                      </h1>
                                                </div>
                                                <div className="vr_border sinclairs_border"></div>
                                                <div className="col-xl-7 pad_right">
                                                      <p className="services_content teatox_text mb-3">At aBox, we executed a comprehensive Figma design and Shopify development for G. Pulla Reddy Sweets, enhancing their online presence while honoring their rich heritage. Our goal was to create a user-friendly platform that showcases their traditional delicacies effectively.</p>
                                                      <p className="services_content teatox_text">We implemented a visually appealing design that resonates with the brand's values of purity and authenticity. By integrating seamless navigation and optimized product displays, we ensured that customers could easily explore and appreciate the legacy of G. Pulla Reddy Sweets.</p>
                                                      <ul className="official_web">
                                                            <li>
                                                                  <a href="" target="_blank" className="d-flex align-items-center link_btn sinclairs_link ayurmedix_text">Official Website
                                                                        <SiteLink />
                                                                  </a>
                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="col-xl-5">
                                                      <div className="row">
                                                            <div className="col-md-6">
                                                                  <p className="sinclairs_title services_menu">Services</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list sinclairs_list">
                                                                        <li>E-Commerce</li>
                                                                        <li>Shopify</li>
                                                                        <li>Branding</li>
                                                                        <li>UI/UX</li>
                                                                        <li>Web Development</li>
                                                                        <li>Onsite SEO</li>
                                                                  </ul>
                                                            </div>
                                                            <div className="menu_list_border sinclairs_border"></div>
                                                            <div className="col-md-6">
                                                                  <p className="sinclairs_title services_menu">Industries</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list sinclairs_list">
                                                                        <li>Confectionery sweets</li>
                                                                        <li>E-Commerce</li>
                                                                  </ul>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Logo section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="bg_banner d-flex justify-content-center align-items-center bg_dark">
                                                <img src="/assets/image/portfolios/sinclairs-hotel/logo.svg" className="banner_logo" alt="Logo" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div>
                                                <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel1.webp" className="w-100" alt="Sinclairs Hotel" />
                                                <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel2.webp" className="w-100" alt="Sinclairs Hotel" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* content Section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row justify-content-center">
                                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                                      <h2 className="brand_title sinclairs_title">Improving User Behavior and Brand Heritage</h2>
                                                      <p className="services_pera teatox_text font_regular mb-0">
                                                            We enhanced the website's design and functionality by incorporating user-centric elements and storytelling features. This included improved navigation, engaging product showcases, and a compelling representation of the brand's history and values.
                                                      </p>
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                                <div className="gaps gap_20 gaps_columns">
                                                      <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel3.webp"
                                                            alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      <div>
                                                            <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel4.webp"
                                                                  alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel5.webp"
                                                                  alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                                <div>
                                                      <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel6.webp"
                                                            alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel7.webp"
                                                            alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mt_20 mb-0 bg_transparent">
                                                <div>
                                                      <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel8.webp"
                                                            alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                </div>
                                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                                      <div>
                                                            <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel9.webp"
                                                                  alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      </div>
                                                      <div className="d-flex justify-content-between flex-column gap_20">
                                                            <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel10.webp"
                                                                  alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Digital brand section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                                      <h2 className="brand_title sinclairs_title">Bringing Tradition to the Online World</h2>
                                                </div>
                                                <div className="col-12">
                                                      <p className="services_pera teatox_text mb-0">
                                                            The revamped website now attracts a wider audience, increasing engagement and sales. Explore the live site to experience the perfect blend of tradition and modernity <a href="#" target="_blank" className="portfolio_a teatox_text">Maadico</a>.</p>
                                                </div>
                                          </div>
                                          <div className="strenth_bg strenth_my_80 p-0 mb-0 bg_transparent">
                                                <div className="gaps gaps_columns">
                                                      <img src="/assets/image/portfolios/sinclairs-hotel/sinclairs-hotel11.webp"
                                                            alt="Sinclairs Hotel" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Testimonial section */}
                        <div className="perform_space py-5">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="teatox_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s sinclairs_title">AuroLab</h3>
                                    <p className="font_medium services_pera teatox_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                                    </div>
                              </div>
                        </div>

                        {/* other project */}
                        <div className="next_project_bg anand_sweet_bg">
                              <div className="container">
                                    <div className="text-center">
                                          {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                                          <a href="/portfolio/anand-sweets" className="next_button font_regular">Next</a>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <Footer />
            </>
      );
}