import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelShishtaka() {

    return (
        <>
            <Helmet>
                <title>Shishtaka: portfolio | Premium Teas - 3D Modeling and Design services | aBox Agency </title>
                <meta name="title" content="Shishtaka: portfolio | Premium Teas - 3D Modeling and Design services | aBox Agency" />
                <meta name="description" content="Explore our partnership with Shishtaka, a premium herbal tea brand. We brought their vision of holistic wellness to life through 3D models, unique designs, and compelling infographics, making each product a testament to their unique approach. Experience the narrative of resilience and uniqueness in every product, making a statement in the eCommerce market." />
                <meta name="keywords" content="Shishtaka, Holistic Wellness, Premium Herbal Teas, 3D Modeling, Design Expertise, Packaging, Product Graphics, Infographics, Herbal and Natural Teas, aBox Agency, aBox design service, aBox label design, aBox 3D design services, eCommerce, Wellness Journey, Unique Brand, Storytelling through Design, Resilience, Brand Transformation, Web Agency, Creative Designs, Branding Solutions, Product Showcase, Visual Storytelling, 3D Rendering, Label Design, Labeling, Packaging Design, Product Design, Blender, 3D Visualization, Packaging Innovation, Labeling Solutions, 3D Product Modeling, Blender 3D Design, Innovative Labeling, 3D Render Services, Custom Packaging, Blender Rendering, Design Solutions, 3D Graphics, Product Label Design, Packaging Concepts, Blender Models, 3D Design Techniques, Labeling Standards, Packaging Trends" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/shishtaka/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title mb-0"><span className="text_gred">Shishtaka</span> <br /> <span className="text_black">Holistic Wellness Through <br className="d-xl-block d-none"/> Premium Herbal Teas</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/shishtaka/shishtaka1.webp"
                        alt="shishtaka" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">In our journey with Shistaka, we transformed their vision of holistic wellness into reality. We created 3D models and designs for their herbal teas, reflecting their unique brand. Despite challenges, we successfully narrated their story through our designs, making each product a testament to their unique approach.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Herbal and Natural teas</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ui section */}
                <div className="ui_section">
                    <div className="container">
                        <img src="/assets/image/label/shishtaka/shishtaka2.webp"
                            alt="shishtaka" className="img-fluid w-100" />

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/shishtaka/shishtaka3.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/shishtaka/shishtaka4.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/shishtaka/shishtaka5.webp" className="w-100" alt="shishtaka" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/shishtaka/shishtaka6.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/shishtaka/shishtaka7.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/shishtaka/shishtaka8.webp" className="w-100" alt="shishtaka" />
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/shishtaka/shishtaka9.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/shishtaka/shishtaka10.webp" className="w-100" alt="shishtaka" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}