import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioWobbIndia() {

      return (
            <>
                  <Helmet>
                        <title></title>
                        <meta name="title" content="" />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <link rel="canonical" href="" />
                  </Helmet>

                  <Header />

                  <div className="page_bg bg_wobbindia">
                        <div className="header_padding">
                              {/* hero section */}
                              <div className="section_detail">
                                    <div className="container-fluid p-0">
                                          <div className="d-xl-flex">
                                                <div className="product_space">
                                                      <p className="product_name wobbindia_text2_bg text-white">Wobb India</p>
                                                </div>
                                                <div className="w-100 line_h_0">
                                                      {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/G.Pulla_Reddy_Sweets.mp4" type="video/mp4" />
                                    </video>*/}
                                                      <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* detail section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                                      <p className="product_name wobbindia_text2_bg text-white writing_mod">Published in 2025</p>
                                                      <h1 className="services_title text-white mb-0">
                                                            Wobb India <br />
                                                            <span className="wobbindia_text">
                                                                  Revamping a Century-Old Legacy with Modern Design
                                                            </span>
                                                      </h1>
                                                </div>
                                                <div className="vr_border"></div>
                                                <div className="col-xl-7 pad_right">
                                                      <p className="services_content wobbindia_text mb-3">At aBox, we executed a comprehensive Figma design and Shopify development for G. Pulla Reddy Sweets, enhancing their online presence while honoring their rich heritage. Our goal was to create a user-friendly platform that showcases their traditional delicacies effectively.</p>
                                                      <p className="services_content wobbindia_text">We implemented a visually appealing design that resonates with the brand's values of purity and authenticity. By integrating seamless navigation and optimized product displays, we ensured that customers could easily explore and appreciate the legacy of G. Pulla Reddy Sweets.</p>
                                                      <ul className="official_web">
                                                            <li>
                                                                  <a href="" target="_blank" className="d-flex align-items-center link_btn wobbindia_link">Official Website
                                                                        <SiteLink />
                                                                  </a>
                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="col-xl-5">
                                                      <div className="row">
                                                            <div className="col-md-6">
                                                                  <p className="text-white services_menu">Services</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list wobbindia_list">
                                                                        <li>E-Commerce</li>
                                                                        <li>Shopify</li>
                                                                        <li>Branding</li>
                                                                        <li>UI/UX</li>
                                                                        <li>Web Development</li>
                                                                        <li>Onsite SEO</li>
                                                                  </ul>
                                                            </div>
                                                            <div className="menu_list_border"></div>
                                                            <div className="col-md-6">
                                                                  <p className="text-white services_menu">Industries</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                  <ul className="services_menu_list wobbindia_list">
                                                                        <li>Confectionery sweets</li>
                                                                        <li>E-Commerce</li>
                                                                  </ul>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Logo section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="bg_banner d-flex justify-content-center align-items-center wobbindia_logo">
                                                <img src="/assets/image/portfolios/wobbindia/logo.svg" className="banner_logo" alt="Logo" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div>
                                                <img src="/assets/image/portfolios/wobbindia/wobbindia1.jpg" className="w-100"alt="Wobb India" />
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* content Section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row justify-content-center">
                                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                                      <h2 className="brand_title text-white">Improving User Behavior and Brand Heritage</h2>
                                                      <p className="services_pera wobbindia_text font_regular mb-0">
                                                            We enhanced the website's design and functionality by incorporating user-centric elements and storytelling features. This included improved navigation, engaging product showcases, and a compelling representation of the brand's history and values.
                                                      </p>
                                                </div>
                                          </div>
                                          <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                                <div className="gaps gap_20 gaps_columns">
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia2.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia3.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia4.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia5.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia6.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia7.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia8.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia9.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                </div>
                                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                                      <div>
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia10.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                      <div className="d-flex justify-content-between flex-column gap_20">
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia11.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                            <img src="/assets/image/portfolios/wobbindia/wobbindia12.jpg"
                                                                 alt="Wobb India" className="img-fluid w-100" />
                                                      </div>
                                                </div>
                                                <div className="gaps gap_20 gaps_columns mt_20">
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia13.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                </div>
                                                <div className="aumex sweet_delect wobbindia_banner mt_20 pb-0">
                                                      <div className="gaps gap_20">
                                                            <div>
                                                                  <img src="/assets/image/portfolios/wobbindia/wobbindia14.jpg"
                                                                       alt="Wobb India" className="img-fluid w-100 border-radius-10" />
                                                            </div>
                                                            <div className="gaps gap_20 gaps_columns">
                                                                  <img src="/assets/image/portfolios/wobbindia/wobbindia15.jpg"
                                                                       alt="Wobb India" className="img-fluid w-100 border-radius-10" />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Digital brand section */}
                        <div className="section_space">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                                      <h2 className="brand_title text-white">Bringing Tradition to the Online World</h2>
                                                </div>
                                                <div className="col-12">
                                                      <p className="services_pera wobbindia_text mb-0">
                                                            The revamped website now attracts a wider audience, increasing engagement and sales. Explore the live site to experience the perfect blend of tradition and modernity <a href="#" target="_blank" className="portfolio_a text-white">Maadico</a>.</p>
                                                </div>
                                          </div>
                                          <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                                <div className="gaps gaps_columns">
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia16.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div className="section_space pt-0">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia17.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia18.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia19.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia20.jpg"
                                                           alt="Wobb India" className="img-fluid w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Color Palette section */}
                        <div className="container">
                              <div className="div-block-40 flex-column">
                                    <h3 className="palette_title text-white">Color Palette</h3>
                                    <div>
                                          <img src="/assets/image/portfolios/wobbindia/wobbindia21.jpg" className="w-100" alt="Wobb India color palette" />
                                    </div>
                              </div>
                        </div>

                        {/* Playfair section */}
                        <div className="section_padd">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          <div className="row">
                                                <div className="col-12">
                                                      <img src="/assets/image/portfolios/wobbindia/wobbindia22.jpg" alt="Wobb India font" className="w-100" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Testimonial section */}
                        <div className="perform_space py-5">
                              <div className="container">
                                    <div className="div-block-40 flex-column">
                                          {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="wobbindia_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s text-white">AuroLab</h3>
                                    <p className="font_medium services_pera wobbindia_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                                    </div>
                              </div>
                        </div>

                        {/* other project */}
                        <div className="next_project_bg sevpoori_bg">
                              <div className="container">
                                    <div className="text-center">
                                          {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                                          <a href="/portfolio/sevpoori" className="next_button font_regular">Next</a>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <Footer />
            </>
      );
}