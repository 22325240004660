import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioAyurmedix() {

    return (
        <>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="" />
            </Helmet>

            <Header />

            <div className="page_bg bg_ayurmedix">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name ayurmedix_text2_bg">Ayurmedix</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1739954520_Ayurmedix.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name ayurmedix_text2_bg writing_mod">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Ayurmedix <br />
                                        <span className="ayurmedix_title">
                                            Global Ophthalmic Solutions
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content ayurmedix_text mb-3">Aurolab is a leader in ophthalmic solutions. We developed a service-based website to enhance awareness of Aurolab’s high-quality eye care products and technologies, effectively showcasing their expertise and extensive service offerings.</p>
                                    <p className="services_content ayurmedix_text">Our objective was to create a platform that communicates Aurolab’s mission. We focused on simplifying complex medical information, ensuring users can easily navigate and understand Aurolab's services. Built with Next.js, the site provides a responsive and dynamic user experience.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="" target="_blank" className="d-flex align-items-center link_btn ayurmedix_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="ayurmedix_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list ayurmedix_list">
                                                <li>Next.js</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>3rd Party Integrations</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="ayurmedix_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list ayurmedix_list">
                                                <li>Ophthalmic healthcare solutions</li>
                                                <li>Corporate site</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg-white">
                                <img src="/assets/image/portfolios/ayurmedix/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/ayurmedix/ayurmedix1.jpg" className="w-100" alt="ayurmedix" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title ayurmedix_title">Targeting Ophthalmic Sector with Custom Development</h2>
                                    <p className="services_pera ayurmedix_text font_regular mb-0">
                                        Our work primarily targets the ophthalmic sector, delivering effective digital solutions for eye care and healthcare services. Using Next.js, we developed a clean, professional platform with intuitive navigation and engaging visuals that effectively showcase Aurolab's products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/ayurmedix/ayurmedix2.jpg"
                                        alt="ayurmedix" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/ayurmedix/ayurmedix3.jpg"
                                            alt="ayurmedix" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/ayurmedix/ayurmedix4.jpg"
                                        alt="ayurmedix" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/ayurmedix/ayurmedix5.jpg"
                                            alt="ayurmedix" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/ayurmedix/ayurmedix6.jpg"
                                            alt="ayurmedix" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/ayurmedix/ayurmedix7.jpg"
                                            alt="ayurmedix" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title ayurmedix_title">Enhanced Online Presence and Engagement Results</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera ayurmedix_text mb-0">
                                        Our strategy improved Aurolab's online authority. The new website <a href="" target="_blank" className="official_link ayurmedix_text">www.aurolab.com</a>, increased traffic and user engagement, solidifying their position in the industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/ayurmedix/ayurmedix8.jpg"
                                        alt="Aurolab" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column mb-0">
                            <div className="strenth_bg strenth_my_80 gaps_sweetly mt_20 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/ayurmedix/ayurmedix9.jpg"
                                        alt="ayurmedix" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ayurmedix/ayurmedix10.jpg"
                                        alt="ayurmedix" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <div className="strenth_my_80 mt-0">
                            <img src="/assets/image/portfolios/ayurmedix/ayurmedix11.jpg" className="w-100" alt="ayurmedix" />
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg anand_sweet_bg">
                    <div className="container">
                        <div className="text-center">                            
                            <a href="/portfolio/anand-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}